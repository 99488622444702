import { useContext, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { DocumentMetaContext } from "features/navigation";
import UserContext from "features/user/context/UserContext";

import Mixpanel from "adapters/mixpanel";

export default function useShare({ title = "", url = "", text = "", trackingLocation = "" } = {}) {
  const { user } = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const documentMetaContext = useContext(DocumentMetaContext);

  const urlWithTracking = useMemo(() => {
    const shareUrl = `${url ? window.location.origin + url : documentMetaContext?.url}?${searchParams.toString()}`;

    if (!user.doesSessionExist) return shareUrl;

    return `${shareUrl}${searchParams.length > 0 ? "&" : "?"}utm_source=referral&utm_content=${user.auth0}`;
  }, [user]);

  const handleTrackOnClick = (e, trackingName, cb) => {
    e.stopPropagation();
    Mixpanel.trackButton("Share Button", trackingName, trackingLocation, {
      "Share URL": urlWithTracking,
    });

    if (cb) cb();
  };

  const handleMobileShare = () => {
    if (!navigator.share) return;

    navigator.share({
      title,
      url: urlWithTracking,
      text,
    });
  };

  return {
    handleMobileShare,
    handleTrackOnClick,
    urlWithTracking,
  };
}
