import { forwardRef, useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import EditIcon from "@mui/icons-material/Edit";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import PropTypes from "prop-types";
import { Element } from "react-scroll";
import { ReactSortable } from "react-sortablejs";

import Input from "forms/Input";
import { Button, Collapse, Tooltip } from "ui";

import useGcsFileUpload from "hooks/useGcsFileUpload";
import { formatBytes, writeFilesToInput } from "utils/helpers";

import FormFile from "./FormFile";

const filePropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  file: PropTypes.shape({
    size: PropTypes.number,
  }).isRequired,
  url: PropTypes.string.isRequired,
});

function FileCard({ file, onUpdate, onDelete, error = "" }) {
  const fileDescriptionControlRef = useRef(null);

  return (
    <div className="flex flex-col w-full mb-2">
      <div
        className={`flex items-center p-4 rounded-3xl w-full ${error ? "bg-danger" : "bg-content4"}`}
        onClick={() => fileDescriptionControlRef.current.focus()}
        role="button"
        tabIndex={0}
        data-testid="multi-file-card"
      >
        <Tooltip content="Download File" placement="right">
          <a download href={file.url} onClick={(e) => e.stopPropagation()}>
            <SimCardDownloadIcon className="!size-8 text-primary-foreground" />
          </a>
        </Tooltip>

        <div className="w-full px-4">
          <div className="flex items-center w-full">
            <EditIcon className="text-primary-foreground !size-5 mr-2" />

            <Input
              variant="underlined"
              placeholder={file.name}
              defaultValue={file?.description || file.name}
              onValueChange={onUpdate}
              ref={fileDescriptionControlRef}
              data-testid="multi-file-card-name"
              classNames={{
                input: "!text-primary-foreground",
                inputWrapper: "border-default-400 after:bg-content1",
                innerWrapper: "pb-0",
              }}
            />
          </div>

          {file.file?.size && (
            <small className="font-roman text-default-400" data-testid="multi-file-card-size">
              {formatBytes(file.file.size)}
            </small>
          )}
        </div>

        <CloseIcon
          className="!size-5 rounded-full text-primary-foreground"
          role="button"
          onClick={onDelete}
        />
      </div>

      {error && (
        <span className="py-2 flex items-center text-danger">
          <ErrorOutlineIcon className="mr-2" />
          {error}
        </span>
      )}
    </div>
  );
}

FileCard.propTypes = {
  file: filePropType.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  error: PropTypes.string,
};

const FormMultiFile = forwardRef(
  (
    {
      name,
      maxFiles,
      label,
      fileType,
      defaultValue,
      required,
      className,
      labelClassName,
      fileError,
      ...fileProps
    },
    ref,
  ) => {
    const { files, dispatch, handleFileUpload, handleCancelFileUpload } = useGcsFileUpload(
      fileType,
      null,
      defaultValue,
    );
    const [open, setOpen] = useState(required);
    const fileRef = useRef(null);

    useEffect(() => {
      writeFilesToInput(
        files.map((f) => f.file),
        fileRef.current,
      );

      if (ref) ref.current = files;
    }, [files]);

    const handleUpdateFileDescription = (index, description) => {
      if (description === files[index].name) return;

      dispatch({ type: "UPDATE", payload: { index, update: { description } } });
    };

    const toggleOpen = () => {
      if (required || files.length > 0) return;
      setOpen(!open);
    };

    return (
      <div>
        <div
          className={`flex items-center justify-between mb-2 ${!required ? "bg-content2 p-4 rounded-3xl" : ""}`}
        >
          <label
            className={`text-start	mb-0 ${labelClassName} ${required ? "after:content-['*'] after:text-danger after:ml-0.5 rtl:after:ml-[unset] rtl:after:mr-0.5" : ""}`}
            htmlFor={name}
          >
            {label}
          </label>

          {files.length <= 0 && !required && (
            <Button onClick={toggleOpen} color={open ? "primary" : "success"}>
              {!open ? "Add Optional File" : "Close"}
            </Button>
          )}
        </div>

        <Collapse isOpen={open}>
          <div id="collapse">
            <ReactSortable
              list={files}
              setList={(newFiles) => dispatch({ payload: newFiles.map(({ chosen, ...f }) => f) })}
              animation={200}
              className={files.length > 0 && files.length < maxFiles ? "mb-4" : "0"}
            >
              {files.map((file, index) => (
                <div className="flex items-center" key={file.file_id || file.id}>
                  <Element name={String(file.file_id)} />
                  <DragIndicatorIcon />
                  <FileCard
                    file={file}
                    onUpdate={(description) => handleUpdateFileDescription(index, description)}
                    onDelete={() => dispatch({ type: "REMOVE", payload: file.file_id })}
                    error={fileError(file.file_id)}
                  />
                </div>
              ))}
            </ReactSortable>

            <FormFile.Dropzone
              name={name}
              labelClassName={`pb-10 ${files.length >= maxFiles ? "hidden" : "block"}`}
              onUpdate={handleFileUpload}
              onCancel={handleCancelFileUpload}
              ref={fileRef}
              className={className}
              {...fileProps}
            />
          </div>
        </Collapse>
      </div>
    );
  },
);

FormMultiFile.propTypes = {
  name: PropTypes.string.isRequired,
  maxFiles: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  fileType: PropTypes.oneOf(["technical", "image"]).isRequired,
  fileError: PropTypes.func.isRequired,
  defaultValue: PropTypes.arrayOf(filePropType),
  required: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
};

FormMultiFile.defaultProps = {
  defaultValue: [],
  required: true,
  className: "border-2 rounded-3xl border-foreground bg-content2 hover:bg-focus",
  labelClassName: "",
};

export default FormMultiFile;
