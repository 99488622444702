import { forwardRef } from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

const Container = forwardRef(({ className, children, ...props }, ref) => (
  <div
    className={twMerge("w-full relative px-4 md:px-16 max-w-container", className)}
    ref={ref}
    {...props}
  >
    {children}
  </div>
));
Container.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
Container.defaultProps = {
  className: "",
};

export default Container;
