import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import PropTypes from "prop-types";
import ScrollToTop from "react-scroll-to-top";

export default function ScrollToTopButton({ top = 1000 }) {
  return (
    <ScrollToTop
      smooth
      top={top}
      component={<ArrowUpwardIcon className="bg-content2 rounded-lg !size-10 shadow-xl p-2" />}
      className="border-none rounded-lg flex items-center justify-center bottom-12 mb-12 !bg-content2"
    />
  );
}

ScrollToTopButton.propTypes = {
  top: PropTypes.number,
};
