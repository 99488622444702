import { motion } from "framer-motion";
import PropTypes from "prop-types";

function Collapse({ isOpen = false, children, ...props }) {
  return (
    <div aria-expanded={isOpen} {...props}>
      <motion.div
        style={{ overflow: "hidden" }}
        initial={{ height: 0, opacity: 1 }}
        animate={{
          transition: { type: "tween" },
          height: isOpen ? "auto" : 0,
        }}
      >
        {children}
      </motion.div>
    </div>
  );
}
Collapse.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default Collapse;
