import { useEffect } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import PropTypes from "prop-types";
import { useInView } from "react-intersection-observer";
import { twMerge } from "tailwind-merge";
import { tv } from "tailwind-variants";

import Button from "ui/buttons/Button";

import Spinner from "./Spinner";

const autoScrollLoader = tv({
  slots: {
    base: "text-center my-8",
    error: "text-danger mb-4 flex gap-2 justify-center items-center",
    spinner: "w-full flex justify-center my-8",
  },
});

function AutoScrollLoader({
  fetchNextPage,
  hasNextPage,
  isLoading,
  empty,
  error = null,
  className = "",
  classNames = {},
  children,
}) {
  const { ref, inView } = useInView({ rootMargin: "100%" });
  const {
    base: baseClassName,
    error: errorClassName,
    spinner: spinnerClassName,
  } = autoScrollLoader();

  useEffect(() => {
    if (inView && !isLoading && fetchNextPage && hasNextPage) fetchNextPage();
  }, [inView, isLoading, fetchNextPage, hasNextPage]);

  if (error && !isLoading) {
    return (
      <div className={twMerge(baseClassName(), className, classNames.base)} data-slot="base">
        <span className={twMerge(errorClassName(), classNames.error)} data-slot="error">
          <ErrorIcon />
          {error.message ?? "Something went wrong"}
        </span>

        <Button onClick={fetchNextPage} color="primary">
          Try Again
        </Button>
      </div>
    );
  }

  if (empty && !isLoading) {
    return (
      <div className={twMerge(baseClassName(), className, classNames.base)} data-slot="base">
        {children}
      </div>
    );
  }

  if (!isLoading && !hasNextPage) return null;

  return (
    <div ref={!error ? ref : undefined} className={twMerge(spinnerClassName(), classNames.spinner)}>
      <Spinner size="lg" />
    </div>
  );
}

AutoScrollLoader.propTypes = {
  children: PropTypes.node.isRequired,
  fetchNextPage: PropTypes.func.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  empty: PropTypes.bool.isRequired,
  className: PropTypes.string,
  classNames: PropTypes.shape({
    base: PropTypes.string,
    error: PropTypes.string,
    spinner: PropTypes.string,
  }),
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
};

export default AutoScrollLoader;
