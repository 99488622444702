import { Chip, useCheckbox, useCheckboxGroupContext, VisuallyHidden } from "@nextui-org/react";
import { twMerge } from "tailwind-merge";

export default function ToggleButton(props) {
  const { children, isSelected, getBaseProps, getLabelProps, getInputProps } = useCheckbox({
    ...props,
  });
  const { color } = useCheckboxGroupContext();
  const { className: labelClassName, ...labelProps } = getLabelProps();

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label {...getBaseProps()}>
      <VisuallyHidden>
        <input {...getInputProps()} />
      </VisuallyHidden>

      <Chip
        variant={isSelected ? "solid" : "bordered"}
        color={color}
        className={twMerge("p-2 inline-table min-w-full text-center", labelClassName)}
        {...labelProps}
      >
        {children}
      </Chip>
    </label>
  );
}
