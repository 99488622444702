import { useContext } from "react";
import PropTypes from "prop-types";
import { generatePath, Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { tv } from "tailwind-variants";

import { BriefContext, BriefStatus, BriefStatusModal } from "features/brief";
import { User } from "features/user";

import { Card, Image, ModalClickContainer } from "ui";

import routes from "default/routes";

const briefCard = tv({
  slots: {
    base: "h-[350px] xxl:h-[400px] after:bg-black/40 after:size-full after:absolute text-primary-foreground",
    title: "",
    body: "absolute top-0 z-10 p-5",
    footer:
      "absolute bg-content2/40 bottom-2 w-[calc(100%_-_16px)] ms-2 z-10 justify-between rounded-3xl",
  },
});

export default function BriefCard({ className = "", classNames = {} }) {
  const { brief } = useContext(BriefContext);
  const { base, title, body, footer } = briefCard();

  return (
    <Card className={twMerge(base(), className, classNames.base)} isFooterBlurred>
      <Card.CardBody className={twMerge(body(), classNames.body)}>
        <h2 className={twMerge(title(), classNames.title)}>{brief.title}</h2>
        {brief.type !== "challenge" && (
          <User
            name={brief.organisation.name}
            avatarProps={{
              src: brief.organisation.icon,
            }}
            as={Link}
            to={generatePath(routes.organisation, {
              id: brief.organisation.id,
            })}
            classNames={{
              base: "bg-transparent gap-3 px-0",
              name: "normal-case text-xl hover:before:scale-x-100 hover:before:origin-left relative before:w-full before:h-1 before:origin-left before:transition-transform before:duration-500 before:scale-x-0 before:bg-current before:absolute before:left-0 before:bottom-0",
            }}
          />
        )}
      </Card.CardBody>

      <Card.CardFooter className={twMerge(footer(), classNames.footer)}>
        <ModalClickContainer modal={BriefStatusModal} className="w-full">
          <BriefStatus />
        </ModalClickContainer>
      </Card.CardFooter>

      <Image
        src={brief.banner?.url || brief.thumbnail}
        alt="Brief page card background"
        className="z-0 w-full h-full object-cover"
        removeWrapper
      />
    </Card>
  );
}
BriefCard.propTypes = {
  className: PropTypes.string,
  classNames: PropTypes.shape({
    base: PropTypes.string,
    title: PropTypes.string,
    body: PropTypes.string,
    footer: PropTypes.string,
  }),
};
