import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Mixpanel from "adapters/mixpanel";

export default function usePageViewTracker() {
  const { pathname } = useLocation();

  return useEffect(() => {
    // Register UTM parameters as super properties on the initial page view
    const utmParams = Mixpanel.getUTMParameters();
    if (Object.keys(utmParams).length > 0) Mixpanel.register_once(utmParams);

    // Track page view
    Mixpanel.track_pageview();
  }, [pathname]);
}
