import AddIcon from "@mui/icons-material/Add";
import ImageNotSupportedOutlinedIcon from "@mui/icons-material/ImageNotSupportedOutlined";
import LockIcon from "@mui/icons-material/Lock";

const proposalStatusContent = () => ({
  proposing: {
    icon: AddIcon,
    header: "Still have more ideas?",
    text: "Make another proposal",
    isDisabled: false,
  },
  noProposal: {
    icon: ImageNotSupportedOutlinedIcon,
    header: "The proposal deadline has passed",
    text: "Make sure to enter the next one!",
    isDisabled: true,
  },
});

const submissionStatusContent = () => ({
  noProposal: {
    icon: LockIcon,
    text: "An accepted proposal is needed to create a final submission.",
    isDisabled: true,
  },
  noSubmission: {
    icon: ImageNotSupportedOutlinedIcon,
    header: "The submission deadline has passed",
    text: "Make sure to enter the next one!",
  },
  locked: {
    icon: LockIcon,
    header: "Your proposal was not approved",
    text: "Thank you for your participation!",
    isDisabled: true,
  },
  submitting: {
    icon: AddIcon,
    header: "Still have more ideas?",
    text: "Make another submission",
    isDisabled: false,
  },
  /* add tests for voting and judging */
  voting: {
    icon: LockIcon,
    text: "This brief is now in the voting phase!",
    isDisabled: true,
  },
  judging: {
    icon: LockIcon,
    text: "This brief is now in the judging phase!",
    isDisabled: true,
  },
  completed: {
    icon: AddIcon,
    header: "Still have more ideas?",
    text: "Make another submission",
    isDisabled: false,
  },
});

export { submissionStatusContent, proposalStatusContent };
