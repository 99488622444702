import Heart from "images/icons/lucide-icons/Heart";
import PropTypes from "prop-types";

import { Button } from "ui";

function LikeButton({ isLiked, likeCount, onLikeToggle }) {
  return (
    <div className="relative">
      <Button
        variant="light"
        radius="md"
        onPress={onLikeToggle}
        className={`uppercase group transition-all duration-200 min-w-2 px-1 ${isLiked ? "text-danger-500 scale-110" : "hover:scale-105"}`}
      >
        <div className="flex items-center gap-x-2">
          <Heart
            className={`h-6 w-6 transition-all duration-300 ${isLiked ? "fill-danger-500" : "fill-transparent"}`}
          />
          {likeCount > 0 && <span className="text-sm font-medium">{likeCount}</span>}
        </div>
      </Button>
    </div>
  );
}

LikeButton.propTypes = {
  likeCount: PropTypes.number.isRequired,
  isLiked: PropTypes.bool.isRequired,
  onLikeToggle: PropTypes.func.isRequired,
};

export default LikeButton;
