import { useState } from "react";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";
import { tv } from "tailwind-variants";

import { ShareButton, useShare } from "features/user";

import { Collapse } from "ui";

import { mobileAndTabletCheck } from "utils/helpers";

import StickyActionButton from "./StickyActionButton";

const stickyActionShareButton = tv({
  slots: {
    wrapper: "overflow-hidden inline-block rounded-full",
    content: "p-1",
  },
  variants: {
    color: {
      default: {
        wrapper: "bg-content1 text-foreground",
        content: "bg-content1",
      },
      primary: {
        wrapper: "bg-primary text-primary-foreground",
        content: "bg-primary",
      },
    },
  },
});

export default function StickyActionShareButton({
  color = "default",
  title = "",
  url = "",
  trackingLocation = "",
  types = ["facebook", "twitter", "whatsapp"],
  classNames = {},
  shareButtonProps = {},
  ...buttonProps
}) {
  const [shareOpen, setShareOpen] = useState(false);
  const { wrapper, content } = stickyActionShareButton({ color });
  const { handleMobileShare } = useShare({ url, title, trackingLocation });

  if (mobileAndTabletCheck()) {
    return (
      <StickyActionButton
        onClick={handleMobileShare}
        title="Share"
        tooltip="Share"
        icon={ShareOutlinedIcon}
        color={color}
        classNames={{
          wrapper: twMerge(wrapper(), classNames.wrapper),
          title: classNames.title,
        }}
        {...buttonProps}
      />
    );
  }
  return (
    <StickyActionButton
      onClick={() => setShareOpen(!shareOpen)}
      title="Share"
      tooltip="Share"
      icon={ShareOutlinedIcon}
      classNames={{
        base: twMerge(shareOpen ? "brightness-75" : "", classNames?.base),
        wrapper: twMerge(wrapper(), classNames?.wrapper),
        title: classNames.title,
      }}
      color={color}
      {...buttonProps}
    >
      <Collapse isOpen={shareOpen}>
        <div className={twMerge(content(), classNames?.content)} data-role="content">
          <ShareButton {...{ title, url, types, trackingLocation, color }} {...shareButtonProps} />
        </div>
      </Collapse>
    </StickyActionButton>
  );
}
StickyActionShareButton.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  trackingLocation: PropTypes.string,
  types: PropTypes.arrayOf(PropTypes.string),
  classNames: PropTypes.shape({
    base: PropTypes.string,
    wrapper: PropTypes.string,
    content: PropTypes.string,
  }),
  shareButtonProps: PropTypes.shape({}),
};
