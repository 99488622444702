import { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import DocumentMeta from "react-document-meta";
import { useLocation } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { DocumentMetaContext, NavigationConfigContext } from "features/navigation";

import { Spinner } from "ui";

import usePageViewTracker from "hooks/usePageViewTracker";

import ProspectFooter from "./ProspectFooter";
import ProspectNavbar from "./ProspectNavbar";

const defaultMeta = {
  title: "Oditi",
  meta: {
    image: "https://prospect100.com/static/media/prospect100icon.29fb2fdd8fd24d5a42e6.png",
    description: "The home of design competitions",
    type: "website",
  },
};

export default function PageLayout({
  children,
  className = "",
  isLoading = false,
  meta = defaultMeta.meta,
  pageName = "",
  showNav = true,
  showFooter = true,
  navbarProps = {},
}) {
  const { config } = useContext(NavigationConfigContext);
  const location = useLocation();
  usePageViewTracker();

  meta.image = meta?.image || defaultMeta.meta.image;
  meta.description = meta?.description || defaultMeta.meta.description;
  meta.type = meta?.type || defaultMeta.meta.type;
  meta.url = window.location.href;

  useEffect(() => {
    if (location.state && location.state.scrollToTop === false) {
      window.history.replaceState({}, document.title);
      return;
    }

    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [location]);

  return (
    <DocumentMetaContext.Provider value={meta}>
      <DocumentMeta
        title={[defaultMeta.title, ...[config?.baseName].concat(pageName)]
          .filter(Boolean)
          .join(" | ")}
        description={meta.description}
        canonical={meta.url}
        meta={{
          property: {
            "og:image": meta.image,
            "og:type": meta.type,
            "og:description": meta.description,
            "og:url": meta.url,
            "og:site_name": "Oditi",
          },
        }}
      >
        <div
          className={twMerge("min-h-dvh flex flex-col justify-between", className)}
          data-testid={`${[...[""].concat(pageName)].filter(Boolean).join("-").toLocaleLowerCase()}-page`}
        >
          <div className="flex flex-col grow">
            {showNav && <ProspectNavbar {...navbarProps} />}

            {isLoading ? (
              <div className="flex grow items-center justify-center">
                <Spinner size="lg" />
              </div>
            ) : (
              children
            )}
          </div>

          {showFooter && <ProspectFooter />}
        </div>
      </DocumentMeta>
    </DocumentMetaContext.Provider>
  );
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  meta: PropTypes.shape({
    image: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    url: PropTypes.string,
  }),
  pageName: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  showNav: PropTypes.bool,
  showFooter: PropTypes.bool,
  navbarProps: PropTypes.shape({}),
};
