import PublicIcon from "@mui/icons-material/Public";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { ShareButton } from "features/user";

import { Button, Modal, Progress } from "ui";

const REQUIREDVOTES = 20;

export default function ShareSubmissionModal({
  onHide,
  shareUrl,
  votes = 0,
  postSubmission = false,
  show = false,
}) {
  return (
    <Modal isOpen={show} onClose={onHide} data-testid="share-submission-modal">
      <Modal.ModalHeader className="flex items-center gap-2">
        {postSubmission ? (
          <>Congratulations!</>
        ) : (
          <>
            <PublicIcon /> Share your submission
          </>
        )}
      </Modal.ModalHeader>

      <Modal.ModalBody className="flex flex-col gap-4">
        <p className="mb-0">
          {postSubmission && "Your Submission has been published!"} Share your work now and get
          supporters early!
        </p>

        <div className="bg-content2 p-4 rounded-3xl flex flex-col gap-2">
          <Progress
            value={(votes / REQUIREDVOTES) * 100}
            label={`${votes} / ${REQUIREDVOTES} Votes`}
            showValueLabel
          />

          {votes >= REQUIREDVOTES && (
            <small>✨ Your submission will be considered for spotlight!</small>
          )}
        </div>

        <small className="font-roman">
          Submissions with 20 votes have a change to be spotlighted and featured on the Oditi
          Instagram!
        </small>

        <ShareButton
          direction="horizontal"
          url={shareUrl}
          color="primary"
          classNames={{
            base: "w-11 h-11",
            icon: "!size-6",
            wrapper: "justify-around my-4",
          }}
          showLabels
        />

        {postSubmission && (
          <div className="bg-content2 p-4 rounded-3xl flex flex-col gap-2 mb-4">
            <p className="mb-0">Get notified for updates on your submission?</p>

            <Button
              color="primary"
              as={Link}
              to="https://www.instagram.com/_oditi?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
            >
              Follow Us
            </Button>

            <small className="font-roman text-xs">
              (Don&apos;t worry - you can unfollow us later!)
            </small>
          </div>
        )}
      </Modal.ModalBody>
    </Modal>
  );
}
ShareSubmissionModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  shareUrl: PropTypes.string.isRequired,
  votes: PropTypes.number,
  postSubmission: PropTypes.bool,
  show: PropTypes.bool,
};
