import { useContext } from "react";
import { toast } from "react-toastify";

import { useProtectedAction } from "features/authentication";
import { ErrorToast } from "features/report";
import { submissionQueries, useSubmission } from "features/submission/api";
import { UserContext } from "features/user";

import { useMutation, useQueryClient } from "api";
import api from "adapters/api";

// TODO: move to api file like with feedback
const useSubmissionWithActions = (submissionId) => {
  const queryClient = useQueryClient();
  const { data: submission, isLoading } = useSubmission(submissionId);
  const { user } = useContext(UserContext);

  const useSubmissionVote = useMutation({
    mutationFn: async () => {
      const res = await api.put({ url: `submissions/${submissionId}/vote` });
      if (!res.success) {
        toast(
          <ErrorToast
            errorMessage={res.errorDetails.message}
            errorProps={{
              defaultReason: "issue",
              defaultPage: "submission_vote",
              apiError: res.errorDetails,
            }}
          />,
          { limit: 1 },
        );
      }
    },
    onSuccess: () => {
      queryClient.setQueryData(submissionQueries.detail(submissionId), (prev) => ({
        ...prev,
        votes: prev.votes + 1,
        voted: true,
      }));
    },
    onError: (error) =>
      toast(
        <ErrorToast
          errorMessage={error.message}
          errorProps={{
            defaultReason: "issue",
            defaultPage: "submission_vote",
            apiError: error,
          }}
        />,
        { limit: 1 },
      ),
  });

  const [handleSelectWinner] = useProtectedAction(async () => {
    const comment = "winner";
    const res = await api.put({
      url: `organisations/${user.organisation.id}/submissions/${submissionId}/winner`,
      data: { comment },
    });

    if (res.success) {
      queryClient.invalidateQueries({ queryKey: submissionQueries.lists() });
      queryClient.setQueryData(submissionQueries.detail(submissionId), (prev) => ({
        ...prev,
        is_winner: comment,
      }));
    }
  });

  const [handleDeselectWinner] = useProtectedAction(async () => {
    const res = await api.delete({
      url: `organisations/${user.organisation.id}/submissions/${submissionId}/winner`,
    });

    if (res.success) {
      queryClient.invalidateQueries({ queryKey: submissionQueries.lists() });
      queryClient.setQueryData(submissionQueries.detail(submissionId), (prev) => ({
        ...prev,
        is_winner: null,
      }));
    }
  });

  return {
    submission,
    isLoading,
    useSubmissionVote,
    handleSelectWinner,
    handleDeselectWinner,
  };
};

export default useSubmissionWithActions;
