import { Select, SelectItem } from "@nextui-org/react";
import styled from "styled-components";
import { twMerge } from "tailwind-merge";

const StyledSelect = styled(Select).attrs((props) => ({
  radius: props.radius ?? "lg",
  listboxProps: {
    classNames: {
      list: twMerge("m-0 p-0 list-none", props.listboxProps?.classNames.list || ""),
    },
    itemClasses: {
      base: twMerge("rounded-full px-4", props.listboxProps?.itemClasses.base || ""),
    },
  },
}))``;

StyledSelect.SelectItem = SelectItem;
export default StyledSelect;
