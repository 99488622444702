import { Listbox } from "@nextui-org/react";
import styled from "styled-components";
import { twMerge } from "tailwind-merge";

const StyledListbox = styled(Listbox).attrs((props) => ({
  classNames: {
    list: twMerge("m-0 p-0 uppercase", props.classNames?.list || ""),
  },
}))``;

export default StyledListbox;
