import { useContext, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { SmallBriefCard, useBriefs } from "features/brief";
import { UserContext } from "features/user";

import { Swiper } from "ui";

export default function BriefSwiper() {
  const { user } = useContext(UserContext);
  if (!user.doesSessionExist) return null;
  const [toggleHide, setToggleHide] = useState(false);
  const inProgressBriefs = useBriefs({
    user: user.username,
    status: ["proposing", "selecting", "submitting", "voting", "judging", "completed"],
  });

  if (inProgressBriefs.all.length <= 0) return null;

  return (
    <div
      className="select-none bg-content4 text-primary-foreground py-4 rounded-3xl mb-4"
      role="button"
      tabIndex={0}
      onClick={() => setToggleHide(!toggleHide)}
    >
      <div className="mx-4 flex justify-between items-center">
        <span className="mx-2 uppercase text-xl sm:text-2xl">
          {`Your Briefs (${inProgressBriefs.total})`}
        </span>
        <KeyboardArrowDownIcon className={`!size-10 ${!toggleHide ? "rotate-180" : ""}`} />
      </div>
      {!toggleHide && (
        <div className="mt-4">
          <Swiper color="primary">
            {inProgressBriefs.all
              .sort((a, b) => a.submitted - b.submitted)
              .map((brief) => (
                <SmallBriefCard entry={brief} trackingLocation="your briefs" />
              ))}
          </Swiper>
        </div>
      )}
    </div>
  );
}
