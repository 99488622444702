import { forwardRef } from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

const Form = forwardRef(({ onSubmit, className, children }, ref) => (
  <form onSubmit={onSubmit} ref={ref} className={twMerge("flex flex-col gap-5", className)}>
    {children}
  </form>
));
Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
Form.defaultProps = {
  className: "",
};

export default Form;
