import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import { briefPropTypes, BriefStatusModal } from "features/brief";

import { ModalClickContainer } from "ui";

function BriefDateAttribute({ title, attribute = "", last = false }) {
  if (!attribute || attribute === "") return null;
  const completed = Date.now() >= dayjs(attribute);

  return (
    <div className="flex relative" data-testid="brief-details">
      <div className="flex flex-col items-center mr-5">
        <span
          className={twMerge(
            "rounded-full !size-4 block",
            completed && "bg-content4",
            !completed && "bg-content3",
          )}
        />

        {!last && (
          <span
            className={twMerge(
              "border grow my-2",
              completed && "border-content4",
              !completed && "border-content3",
            )}
          />
        )}
      </div>
      <div>
        <h6 className="uppercase font-bold">{title}:</h6>
        <p className="text-sm">{dayjs(attribute).format("MMM. DD, HH:mm")}</p>
      </div>
    </div>
  );
}
BriefDateAttribute.propTypes = {
  attribute: PropTypes.string,
  title: PropTypes.string.isRequired,
  last: PropTypes.bool,
};

function BriefTimeline({ brief }) {
  return (
    <ModalClickContainer modal={BriefStatusModal} className="hidden xl:block">
      <div className="p-4 bg-content2 rounded-3xl relative">
        <div className="flex items-top justify-between mb-3">
          <span className="text-sm text-default-400 uppercase mt-1">
            Key Dates (In Your Local Timezone)
          </span>
          <HelpOutlineIcon className="!size-8" />
        </div>
        <div className="mt-4">
          <BriefDateAttribute attribute={brief.start_time} title="Launch" />
          <BriefDateAttribute attribute={brief.proposal_deadline} title="Proposal Deadline" />
          <BriefDateAttribute attribute={brief.proposal_selected} title="Proposal Selected" />
          <BriefDateAttribute attribute={brief.submission_deadline} title="Submission Deadline" />
          <BriefDateAttribute attribute={brief.voting_deadline} title="Voting Deadline" />
          <BriefDateAttribute attribute={brief.winners_selected} title="Winner Announcement" last />
        </div>
      </div>
    </ModalClickContainer>
  );
}
BriefTimeline.propTypes = {
  brief: briefPropTypes.isRequired,
};

export default BriefTimeline;
