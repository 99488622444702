import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
  createRoutesFromChildren,
  createBrowserRouter as defaultCreateBrowserRouter,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

const init = () => {
  if (!process.env.REACT_APP_SENTRY_DSN) return;

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENV || "dev",
    release: process.env.REACT_APP_COMMIT_SHA,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          process.env.REACT_APP_DEFAULT_URL,
          process.env.REACT_APP_ORG_URL,
          process.env.REACT_APP_API_SERVER_URL + process.env.REACT_APP_API_BASE_URL,
        ],
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  Sentry.setTag("app_type", process.env.REACT_APP_TYPE);
};

const createBrowserRouter = Sentry.wrapCreateBrowserRouter(defaultCreateBrowserRouter);

export default {
  ...Sentry, // re-export
  init, // Override default
  createBrowserRouter,
};
