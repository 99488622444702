import { useContext, useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import FacebookIcon from "icons/FacebookIcon";
import LinkIcon from "icons/LinkIcon";
import TwitterIcon from "icons/TwitterIcon";
import WhatsappIcon from "icons/WhatsappIcon";
import PropTypes from "prop-types";
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import { twMerge } from "tailwind-merge";
import { tv } from "tailwind-variants";

import { DocumentMetaContext } from "features/navigation";
import useShare from "features/user/hooks/useShare";

import Button from "ui/buttons/Button";
import Tooltip from "ui/popups/Tooltip";

import { mobileAndTabletCheck } from "utils/helpers";

const shareTypes = {
  facebook: {
    button: FacebookShareButton,
    icon: FacebookIcon,
  },
  twitter: {
    button: TwitterShareButton,
    icon: TwitterIcon,
  },
  whatsapp: {
    button: WhatsappShareButton,
    icon: WhatsappIcon,
  },
};

const shareButton = tv({
  slots: {
    wrapper: "overflow-x-scroll scrollbar-hide",
    icon: "!size-5",
    content: "flex flex-col items-center gap-2",
    label: "font-roman text-center capitalize",
  },
  variants: {
    direction: {
      vertical: {
        wrapper: "flex flex-col gap-y-2",
      },
      horizontal: {
        wrapper: "flex flex-row gap-x-2",
      },
    },
  },
});

export default function ShareButton({
  direction = "vertical",
  title = "",
  text = "",
  url = "",
  trackingLocation = "",
  className = "",
  types = ["facebook", "twitter", "whatsapp", "link"],
  classNames = {},
  tooltipProps = {},
  showMoreOptions = mobileAndTabletCheck(),
  showLabels = false,
  ...buttonProps
}) {
  const [shareClicked, setShareClicked] = useState(false);
  const { urlWithTracking, handleTrackOnClick, handleMobileShare } = useShare({
    title,
    text,
    url,
    trackingLocation,
  });
  const { wrapper, content, icon, label } = shareButton({ direction });
  const documentMetaContext = useContext(DocumentMetaContext);

  const copyLink = () => {
    navigator.clipboard.writeText(urlWithTracking);
    setShareClicked(true);

    // Reset back after a fixed time
    setTimeout(() => {
      setShareClicked(false);
    }, 2000);
  };

  return (
    <div className={twMerge(wrapper(), classNames?.wrapper)} data-slot="wrapper">
      {types.map((type) => {
        if (!(type in shareTypes)) return null;

        const ShareButtonComponent = shareTypes[type].button;
        const ShareIcon = shareTypes[type].icon;

        return (
          <Tooltip content={type} placement="left" isDisabled={showLabels} {...tooltipProps}>
            <div className={twMerge(content(), classNames.content)} data-slot="content">
              <Button
                size="sm"
                isIconOnly
                className={twMerge(className, classNames?.base)}
                {...buttonProps}
              >
                <ShareButtonComponent
                  url={urlWithTracking}
                  title={title || documentMetaContext?.description}
                  onClick={(e) => handleTrackOnClick(e, type)}
                >
                  <ShareIcon className={twMerge(icon(), classNames.icon)} data-slot="icon" />
                </ShareButtonComponent>
              </Button>

              {showLabels && (
                <small className={twMerge(label(), classNames.label)} data-slot="label">
                  {type}
                </small>
              )}
            </div>
          </Tooltip>
        );
      })}

      {types.includes("link") && (
        <Tooltip
          content={shareClicked ? "Link Copied" : "Copy Link"}
          placement="left"
          isDisabled={showLabels}
          {...tooltipProps}
        >
          <div className={twMerge(content(), classNames.content)} data-slot="content">
            <Button
              isIconOnly
              size="sm"
              onClick={(e) => {
                handleTrackOnClick(e, "link", copyLink);
              }}
              className={twMerge(className, classNames?.base)}
              {...buttonProps}
            >
              {shareClicked ? (
                <VerifiedOutlinedIcon
                  className={twMerge(icon(), classNames.icon)}
                  data-slot="icon"
                />
              ) : (
                <LinkIcon className={twMerge(icon(), classNames.icon)} data-slot="icon" />
              )}
            </Button>

            {showLabels && (
              <small className={twMerge(label(), classNames.label)} data-slot="label">
                Copy Link
              </small>
            )}
          </div>
        </Tooltip>
      )}

      {showMoreOptions && (
        <Tooltip content="More" placement="left" isDisabled={showLabels} {...tooltipProps}>
          <div className={twMerge(content(), classNames.content)} data-slot="content">
            <Button
              size="sm"
              onClick={handleMobileShare}
              isIconOnly
              className={twMerge(className, classNames.base)}
              {...buttonProps}
            >
              <MoreHorizIcon className={twMerge(icon(), classNames.icon)} data-slot="icon" />
            </Button>

            {showLabels && (
              <small className={twMerge(label(), classNames.label)} data-slot="label">
                More
              </small>
            )}
          </div>
        </Tooltip>
      )}
    </div>
  );
}
ShareButton.propTypes = {
  direction: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string,
  trackingLocation: PropTypes.string,
  types: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  classNames: PropTypes.shape({
    base: PropTypes.string,
    wrapper: PropTypes.string,
    icon: PropTypes.string,
  }),
  tooltipProps: PropTypes.shape({}),
  showMoreOptions: PropTypes.bool,
  showLabels: PropTypes.bool,
};
