import { useMemo, useState } from "react";

export default function useErrorProvider() {
  const [error, setError] = useState({
    show: false,
    options: {},
  });

  const showError = (options) => setError({ ...error, show: true, options });
  const hideError = () => setError({ ...error, show: false });

  const errorContextProviderValue = useMemo(
    () => ({ showError, hideError }),
    [showError, hideError],
  );

  return {
    error,
    errorContextProviderValue,
  };
}
