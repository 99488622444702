import { toast } from "react-toastify";

import { ErrorToast } from "features/report";
import { submissionQueries } from "features/submission/api";

import { useQueryClient } from "api";
import api from "adapters/api";

const useSubmissionDelete = () => {
  const queryClient = useQueryClient();

  const handleDeleteSubmission = async (sumissionId) => {
    const res = await api.delete({ url: `submissions/${sumissionId}` });

    if (!res.success) {
      toast(
        <ErrorToast
          errorMessage={res.errorDetails.message}
          errorProps={{
            defaultReason: "issue",
            defaultPage: "other",
            apiError: res.errorDetails,
          }}
        />,
        {
          limit: 1,
        },
      );
      return;
    }

    queryClient.invalidateQueries({ queryKey: submissionQueries.lists() });
  };

  return handleDeleteSubmission;
};

export default useSubmissionDelete;
