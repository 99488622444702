import { forwardRef } from "react";
import PropTypes from "prop-types";

import useProtectedAction from "features/authentication/hooks/useProtectedAction";

import { ModalClickContainer } from "ui";

const ProtectedModalClickContainer = forwardRef(({ children, ...props }, ref) => {
  const [handleOpenModal] = useProtectedAction((cb) => cb());

  return (
    <ModalClickContainer ref={ref} onOpenModal={handleOpenModal} {...props}>
      {children}
    </ModalClickContainer>
  );
});
ProtectedModalClickContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProtectedModalClickContainer;
