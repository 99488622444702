import { addMethod, array, boolean, mixed, number, object, string } from "yup";

import {
  validateDateTime,
  validateDateTimeAfter,
  validateFileDimensions,
  validateFileRequired,
  validateFileSize,
  validateFileTypes,
} from "./validationMethods";

addMethod(mixed, "fileDimensions", validateFileDimensions);
addMethod(mixed, "fileSize", validateFileSize);
addMethod(mixed, "requiredFile", validateFileRequired);
addMethod(mixed, "fileTypes", validateFileTypes);
addMethod(string, "dateTime", validateDateTime);
addMethod(string, "dateTimeAfter", validateDateTimeAfter);

const enterBriefSchema = object().shape({
  published: boolean(),
  require_working: boolean(),
  title: string().when("published", {
    is: true,
    then: string().required(),
    otherwise: string(),
  }),
  thumbnail: mixed().fileTypes().fileSize(26214400),
  thumbnailUrl: string().when("published", {
    is: true,
    then: string().nullable().required("Thumbnail is a required field"),
    otherwise: string().nullable(),
  }),
  images: mixed().fileTypes().fileSize(26214400),
  publishedImages: array().when("published", {
    is: true,
    then: array().min(1, "Images is a required field").required(),
    otherwise: array(),
  }),
  technical_file: mixed(),
  publishedTechnical_file: array().when(["published", "require_working"], {
    is: true,
    then: array().min(1, "Working file is a required field").required(),
    otherwise: array(),
  }),
  description: string().when("published", {
    is: true,
    then: string().required(),
    otherwise: string(),
  }),
});

const updateBriefTypeSchema = object().shape({
  private: boolean(),
  start_time: string()
    .required("Start Date is a required field")
    .dateTime("YYYY-MM-DD HH:mm", "Start Date is not a valid format"),
  proposal_deadline: string().when("private", {
    is: true,
    then: string()
      .required("Proposal Deadline is a required field")
      .dateTime("YYYY-MM-DD HH:mm", "Proposal Deadline is not a valid format")
      .dateTimeAfter("start_time", "Proposal Deadline must be after the start time."),
    otherwise: string(),
  }),
  proposal_selected: string().when("private", {
    is: true,
    then: string()
      .required("Proposal Selected is a required field")
      .dateTime("YYYY-MM-DD HH:mm", "Proposal Selected is not a valid format")
      .dateTimeAfter("proposal_deadline", "Proposal Selected must be after the proposal deadline."),
    otherwise: string(),
  }),
  submission_deadline: string().when("private", {
    is: true,
    then: string()
      .required("Submission Deadline Date is a required field")
      .dateTime("YYYY-MM-DD HH:mm", "Submission Deadline is not a valid format")
      .dateTimeAfter(
        "proposal_selected",
        "Submission Deadline must be after the proposal selected.",
      ),
    otherwise: string()
      .required("Submission Deadline Date is a required field")
      .dateTime("YYYY-MM-DD HH:mm", "Submission Deadline is not a valid format")
      .dateTimeAfter("start_time", "Submission Deadline must be after the start time."),
  }),
  voting_deadline: string().when("private", {
    is: false,
    then: string()
      .required("Voting Deadline Date is a required field")
      .dateTime("YYYY-MM-DD HH:mm", "Voting Deadline is not a valid format")
      .dateTimeAfter(
        "submission_deadline",
        "Voting Deadline must be after the submission deadline.",
      ),
    otherwise: string(),
  }),
  winners_selected: string().when("private", {
    is: true,
    then: string()
      .required("Winners Selected Date is a required field")
      .dateTime("YYYY-MM-DD HH:mm", "Winners Selected is not a valid format")
      .dateTimeAfter(
        "submission_deadline",
        "Winners Selected must be after the submission deadline.",
      ),
    otherwise: string()
      .required("Winners Selected Date is a required field")
      .dateTime("YYYY-MM-DD HH:mm", "Winners Selected is not a valid format")
      .dateTimeAfter("voting_deadline", "Winners Selected must be after the voting deadline."),
  }),
});

const createBriefInformationSchema = object().shape({
  private: boolean(),
  brief_image: mixed().fileTypes().fileDimensions(2880, 2160),
  publishedBriefImage: string().nullable().required("Brief Image is a required field"),
  title: string()
    .matches(/^\s*(\S+\s+){0,9}\S*\s*$/, "Title cannot be more than 10 words.")
    .required(),
  challenge_statement: string()
    .matches(/^\s*(\S+\s+){0,29}\S*\s*$/, "Challenge Statement cannot be more than 30 words.")
    .required("Challenge Statement is a required field"),
  timezone: string().max(50).required(),
  // We use strings here to use custom validation against moment times (which are more robust)
  start_time: string()
    .required("Start Date is a required field")
    .dateTime("YYYY-MM-DD HH:mm", "Start Date is not a valid format"),
  proposal_deadline: string().when("private", {
    is: true,
    then: string()
      .required("Proposal Deadline is a required field")
      .dateTime("YYYY-MM-DD HH:mm", "Proposal Deadline is not a valid format")
      .dateTimeAfter("start_time", "Proposal Deadline must be after the start time."),
    otherwise: string(),
  }),
  proposal_selected: string().when("private", {
    is: true,
    then: string()
      .required("Proposal Selected is a required field")
      .dateTime("YYYY-MM-DD HH:mm", "Proposal Selected is not a valid format")
      .dateTimeAfter("proposal_deadline", "Proposal Selected must be after the proposal deadline."),
    otherwise: string(),
  }),
  submission_deadline: string().when("private", {
    is: true,
    then: string()
      .required("Submission Deadline Date is a required field")
      .dateTime("YYYY-MM-DD HH:mm", "Submission Deadline is not a valid format")
      .dateTimeAfter(
        "proposal_selected",
        "Submission Deadline must be after the proposal selected.",
      ),
    otherwise: string()
      .required("Submission Deadline Date is a required field")
      .dateTime("YYYY-MM-DD HH:mm", "Submission Deadline is not a valid format")
      .dateTimeAfter("start_time", "Submission Deadline must be after the start time."),
  }),
  voting_deadline: string().when("private", {
    is: false,
    then: string()
      .required("Voting Deadline Date is a required field")
      .dateTime("YYYY-MM-DD HH:mm", "Voting Deadline is not a valid format")
      .dateTimeAfter(
        "submission_deadline",
        "Voting Deadline must be after the submission deadline.",
      ),
    otherwise: string(),
  }),
  winners_selected: string().when("private", {
    is: true,
    then: string()
      .required("Winners Selected Date is a required field")
      .dateTime("YYYY-MM-DD HH:mm", "Winners Selected is not a valid format")
      .dateTimeAfter(
        "submission_deadline",
        "Winners Selected must be after the submission deadline.",
      ),
    otherwise: string()
      .required("Winners Selected Date is a required field")
      .dateTime("YYYY-MM-DD HH:mm", "Winners Selected is not a valid format")
      .dateTimeAfter("voting_deadline", "Winners Selected must be after the voting deadline."),
  }),
  resources: mixed(),
});

const createBriefIncentivesSchema = object().shape({
  prize_money: number()
    .typeError("Prize Money must be a number")
    .nullable()
    .moreThan(0, "Prize Money cannot be negative or 0")
    .transform((_, val) => (val !== "" ? Number(val) : null)),
  currency: string().when("prize_money", (prizeMoney, schema) => {
    if (prizeMoney > 0)
      return schema.required("Currency must be supplied if prize money is supplied.");

    return schema;
  }),
  description: string().matches(
    /^\s*(\S+\s+){0,499}\S*\s*$/,
    "Description cannot be more than 500 words.",
  ),
  deliverables: string().matches(
    /^\s*(\S+\s+){0,29}\S*\s*$/,
    "Deliverables cannot be more than 30 words.",
  ),
  other_incentives: string().matches(
    /^\s*(\S+\s+){0,499}\S*\s*$/,
    "Other Incentives cannot be more than 500 words.",
  ),
  things_to_consider: string().matches(
    /^\s*(\S+\s+){0,499}\S*\s*$/,
    "Submission Guidelines cannot be more than 500 words.",
  ),
  selection_criteria: string().matches(
    /^\s*(\S+\s+){0,499}\S*\s*$/,
    "Selection Criteria cannot be more than 500 words.",
  ),
});

export {
  enterBriefSchema,
  updateBriefTypeSchema,
  createBriefInformationSchema,
  createBriefIncentivesSchema,
};
