import PropTypes from "prop-types";

import Modal from "./Modal";

export default function ConfirmationModal({
  onHide,
  promptText,
  action,
  cancelText = undefined,
  confirmText = undefined,
  cancelProps = {},
  confirmProps = {},
  show = false,
}) {
  const handleAction = (e) => {
    onHide(); // Want to hide the modal before we complete the action
    action(e);
  };

  return (
    <Modal isOpen={show} onOpenChange={onHide} data-testid="confirmation-modal">
      <Modal.ModalHeader>Are You Sure?</Modal.ModalHeader>

      <Modal.ModalBody>
        <small className="font-roman">{promptText}</small>
      </Modal.ModalBody>

      <Modal.ModalActionsFooter
        onCancel={onHide}
        onConfirm={handleAction}
        cancelText={cancelText}
        confirmText={confirmText}
        cancelProps={{
          "data-testid": "confirmation-modal-cancel",
          ...cancelProps,
        }}
        confirmProps={{
          "data-testid": "confirmation-modal-confirm",
          ...confirmProps,
        }}
      />
    </Modal>
  );
}

ConfirmationModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  promptText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  action: PropTypes.func.isRequired,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  cancelProps: PropTypes.shape({}),
  confirmProps: PropTypes.shape({}),
  show: PropTypes.bool,
};
