import { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { tv } from "tailwind-variants";

import { DocumentMetaContext } from "features/navigation";
import SubmissionBadge from "features/submission/components/badge/SubmissionBadge";
import SubmissionOptionsDropdown from "features/submission/components/dropdown/SubmissionOptionsDropdown";
import { submissionListPropTypes } from "features/submission/schemas/submissionSchema";
import { User, UserContext } from "features/user";

import { Card, Chip, Image } from "ui";

import routes from "default/routes";

const submissionCard = tv({
  slots: {
    base: "h-full w-full aspect-4/3 old-hover-overlay",
    header: "h-full w-full absolute top-0 z-10 old-overlay-fade-top flex-col items-start p-4",
    footer: "absolute bottom-0 z-10 p-4 flex flex-wrap items-center justify-end w-full gap-2",
    body: "absolute top-0 z-10 p-4 w-full h-full",
    image: "z-0 w-full h-full object-cover",
    title: "text-foreground bg-content1 p-1 px-2 rounded-full shadow-lg truncate",
    titleWrapper: "flex items-center max-w-[75%] gap-2",
  },
});

const submissionBadgeContent = (submission, isOrg = false) => ({
  get proposal() {
    if (!submission.published || submission.brief.status === "proposing") return null;
    if (submission.brief.status === "selecting" && (!isOrg || (isOrg && !submission.is_winner)))
      return "pending";

    if (!submission.is_winner) return "failed";

    return "approved";
  },
  get submission() {
    if (!submission.published || submission.brief.status !== "completed" || !submission.is_winner)
      return null;
    return "winner";
  },
});

export default function SubmissionCard({
  entry: submission,
  onClick = undefined,
  className = "",
  classNames = {},
  ...optionProps
}) {
  const { user } = useContext(UserContext);
  const { base, header, footer, body, image, title, titleWrapper } = submissionCard();
  const navigate = useNavigate();
  const location = useLocation();
  const documentMetaContext = useContext(DocumentMetaContext);
  const submissionBadgeType = useMemo(
    () => submissionBadgeContent(submission, user.organisation?.id)[submission.type],
    [submission],
  );
  const isOwnSubmission = user.id === submission.user.id;

  const navigateOnClick = () => {
    if (onClick) {
      onClick(submission);
      return;
    }

    const current = documentMetaContext?.currentRoute || "voting";
    navigate(
      {
        pathname: generatePath(routes[`${current}Submission`], {
          username: submission.user.username,
          submissionId: submission.id,
        }),
        search: location.search,
      },
      {
        state: { scrollToTop: false },
      },
    );
  };

  return (
    <Card
      isPressable
      allowTextSelectionOnPress
      shadow="none"
      className={twMerge(base(), className, classNames.base)}
      onPress={navigateOnClick}
      data-testid="submission-card"
      data-slot="base"
    >
      <Card.CardHeader className={twMerge(header(), classNames.header)} data-slot="header">
        <User
          name={submission.user.username}
          avatarProps={{
            src: submission.user.icon,
          }}
          badges={submission.user.badges}
          classNames={{
            base: "bg-transparent gap-2 p-0",
            name: "text-xl text-primary-foreground",
          }}
        />
      </Card.CardHeader>

      {submissionBadgeType && (
        <Card.CardBody className={twMerge(body(), classNames.body)} data-slot="body">
          <div className="w-full flex justify-end">
            <SubmissionBadge type={submissionBadgeType} />
          </div>
        </Card.CardBody>
      )}

      <Card.CardFooter className={twMerge(footer(), classNames.footer)} data-slot="footer">
        <div className="w-full flex justify-end gap-2">
          {!submission.published && (
            <Chip
              classNames={{
                base: "bg-purple text-primary-foreground",
                dot: "bg-content1",
              }}
              variant="dot"
            >
              Draft
            </Chip>
          )}
          {submission.type === "proposal" && (
            <Chip
              classNames={{
                base: "bg-purple text-primary-foreground",
                dot: "bg-content1",
              }}
              variant="dot"
            >
              Proposal
            </Chip>
          )}
        </div>

        <div className={twMerge(titleWrapper(), classNames.titleWrapper)} data-slot="titleWrapper">
          <span className={twMerge(title(), classNames.title)} data-slot="title">
            {submission.title || `"${submission.brief.title}" Submission`}
          </span>
        </div>

        {isOwnSubmission && <SubmissionOptionsDropdown submission={submission} {...optionProps} />}
      </Card.CardFooter>

      <Image
        src={submission.thumbnail}
        alt="submission card thumbnail"
        removeWrapper
        loading="lazy"
        className={twMerge(image(), classNames.image)}
        data-slot="image"
      />
    </Card>
  );
}
SubmissionCard.propTypes = {
  entry: submissionListPropTypes.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  classNames: PropTypes.shape({
    base: PropTypes.string,
    header: PropTypes.string,
    footer: PropTypes.string,
    body: PropTypes.string,
    image: PropTypes.string,
    title: PropTypes.string,
    titleWrapper: PropTypes.string,
  }),
};
