import { useContext } from "react";
import PropTypes from "prop-types";

import { ErrorToast } from "features/report";
import { useUpdateUser } from "features/user/api";
import UserContext from "features/user/context/UserContext";

import { ControlledUsernameInput, Form, handleFormError, useForm } from "forms";
import { Button } from "ui";

export default function OnboardingUsername({ onComplete }) {
  const { user } = useContext(UserContext);
  const { mutate, isPending } = useUpdateUser();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  return (
    <div data-testid="user-onboarding-username">
      <h2 className="capitalize mb-8 text-4xl">Claim Your Username</h2>

      <Form
        onSubmit={handleSubmit((formData) =>
          mutate(formData, {
            onSuccess: onComplete,
            onError: (error, data) =>
              handleFormError({
                error,
                data,
                setError,
                errorPage: "onboarding",
                toast: ErrorToast,
              }),
          }),
        )}
      >
        <div>
          <small className="text-primary-400 font-roman">Email</small>
          <p>{user.email}</p>
        </div>

        <ControlledUsernameInput
          name="username"
          control={control}
          defaultValue={user.username || ""}
          label="Username"
        />

        <Button
          type="submit"
          color="primary"
          trackingName="continue onboarding"
          isDisabled={!!errors.username || isPending}
          isLoading={isPending}
          fullWidth
        >
          Continue
        </Button>
      </Form>
    </div>
  );
}

OnboardingUsername.propTypes = {
  onComplete: PropTypes.func.isRequired,
};
