import CollectionsIcon from "@mui/icons-material/Collections";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PublicIcon from "@mui/icons-material/Public";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PropTypes from "prop-types";
import { generatePath, useLocation, useNavigate } from "react-router-dom";

import ShareSubmissionModal from "features/submission/components/modal/SubmissionModal/ShareSubmissionModal";
import useSubmissionDelete from "features/submission/hooks/useSubmissionDelete";
import { submissionListPropTypes } from "features/submission/schemas/submissionSchema";

import { Button, ConfirmationModal, Dropdown, ModalClickContainer } from "ui";

import routes from "default/routes";

export default function SubmissionOptionsDropdown({ submission, hideOptions = false }) {
  const location = useLocation();
  const handleDeleteSubmission = useSubmissionDelete();
  const navigate = useNavigate();

  if (hideOptions) return null;

  return (
    <Dropdown
      style={{
        zIndex: 10,
      }}
      classNames={{
        base: "before:bg-default-200 font-roman", // change arrow background
        content: "border border-default-200",
      }}
      data-testid="submission-options-dropdown"
    >
      <Dropdown.DropdownTrigger>
        <Button
          isIconOnly
          variant="light"
          className="bg-content1 shadow-md"
          data-testid="submission-options-button"
        >
          <SettingsOutlinedIcon />
        </Button>
      </Dropdown.DropdownTrigger>

      <Dropdown.DropdownMenu
        closeOnSelect={false}
        onClick={(e) => e.stopPropagation()}
        itemClasses={{
          base: ["rounded-xl py-2 px-3"],
          list: ["normal-case"],
        }}
      >
        {submission.can_edit && (
          <Dropdown.DropdownItem
            key="edit"
            description="Edit your submission"
            startContent={<EditIcon />}
            data-testid="submission-options-edit"
            onClick={() =>
              navigate(
                `${generatePath(routes.enterBrief, { id: submission.brief.id })}?submission=${submission.id}`,
              )
            }
          >
            Edit
          </Dropdown.DropdownItem>
        )}

        {location.pathname.includes("user") && (
          <Dropdown.DropdownItem
            key="workspace"
            description="View submission in workspace"
            startContent={<CollectionsIcon />}
            data-testid="submission-options-workspace"
            onClick={() =>
              navigate(`${generatePath(routes.brief, { id: submission.brief.id })}?tab=workspace`)
            }
          >
            Workspace
          </Dropdown.DropdownItem>
        )}

        {submission.published && (
          <Dropdown.DropdownItem
            key="share"
            description="Share and get votes!"
            startContent={<PublicIcon />}
            as={ModalClickContainer}
            modal={ShareSubmissionModal}
            modalProps={{
              votes: submission.votes,
              shareUrl: generatePath(routes.userProfileSubmission, {
                username: submission.user.username,
                submissionId: submission.id,
              }),
            }}
          >
            Share
          </Dropdown.DropdownItem>
        )}

        <Dropdown.DropdownItem
          key="delete"
          description="Delete your submission forever"
          startContent={<DeleteIcon />}
          color="danger"
          className="text-danger"
          as={ModalClickContainer}
          modal={ConfirmationModal}
          modalProps={{
            promptText: (
              <>
                Are you sure you want to delete this submission?
                <br />
                Please not that this submission will be automatically pulled out from any briefs
                (active or closed) to which this was submitted and cannot be restored.
                <br />
                <b className="text-xs uppercase">
                  Submissions that won a brief cannot be deleted from the platform.
                </b>
              </>
            ),
            action: () => handleDeleteSubmission(submission.id),
            cancelProps: {
              color: "primary",
              variant: "solid",
            },
            confirmProps: {
              color: "danger",
              variant: "flat",
            },
          }}
          data-testid="submission-options-delete"
        >
          Delete
        </Dropdown.DropdownItem>
      </Dropdown.DropdownMenu>
    </Dropdown>
  );
}
SubmissionOptionsDropdown.propTypes = {
  submission: submissionListPropTypes.isRequired,
  hideOptions: PropTypes.bool,
};
