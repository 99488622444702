import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import Button from "ui/buttons/Button";
import Col from "ui/layout/Col";
import Row from "ui/layout/Row";

function CallToAction({ title, description, cta, to, ...ctaProps }) {
  return (
    <Row>
      <Col xs={12} lg={6}>
        <h2 className="lg:mb-0 sm:leading-[70px]">{title}</h2>
      </Col>
      <Col xs={12} lg={6} className="flex flex-col justify-center max-w-[700px]">
        <p>{description}</p>

        <div className="inline-block">
          <Button as={Link} to={to} size="lg" className="w-full sm:w-min" {...ctaProps}>
            {cta}
          </Button>
        </div>
      </Col>
    </Row>
  );
}
CallToAction.propTypes = {
  title: PropTypes.node.isRequired,
  description: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default CallToAction;
