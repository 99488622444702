import { useContext, useMemo } from "react";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import { useDisclosure } from "@nextui-org/react";
import VoteIcon from "icons/VoteIcon";
import PropTypes from "prop-types";

import { useProtectedAction } from "features/authentication";
import { FeedbackPromptModal, useFeedback } from "features/feedback";
import useSubmissionWithActions from "features/submission/hooks/useSubmissionWithActions";
import submissionPropTypes from "features/submission/schemas/submissionSchema";
import { AddUserInfoModal, UserContext } from "features/user";

import { Button } from "ui";

import useAnimateVoteButton from "hooks/useAnimateVoteButton";
import { abbreviateNumber } from "utils/helpers";

import Mixpanel from "adapters/mixpanel";

export default function SubmissionVoteButton({ submission, trackingLocation = "" }) {
  const { user } = useContext(UserContext);
  const { data: feedback } = useFeedback(submission.id);
  const { useSubmissionVote } = useSubmissionWithActions(submission.id);

  const showVotes = submission?.voted && !useSubmissionVote.isPending;
  const hasGivenFeedback = useMemo(
    () => feedback?.comments?.some((f) => f.user.id === user.id),
    [feedback],
  );
  const { animateVerified, animateVotes } = useAnimateVoteButton(showVotes);

  const {
    isOpen: isUserInfoModalOpen,
    onOpen: openUserInfoModal,
    onClose: closeUserInfoModal,
  } = useDisclosure();
  const {
    isOpen: isFeedbackModalOpen,
    onOpen: openFeedbackModal,
    onClose: closeFeedbackModal,
  } = useDisclosure();
  const FEEDBACK_PROMPT_PROBABILITY = 0.4;

  const [handleOnClick] = useProtectedAction(async () => {
    if (showVotes) return;
    Mixpanel.trackButton("Action Button", "vote", trackingLocation);

    if (!(user.dob && user.country_code && user.gender)) {
      openUserInfoModal();
      return;
    }

    await useSubmissionVote.mutateAsync();
    if (!hasGivenFeedback && Math.random() < FEEDBACK_PROMPT_PROBABILITY) openFeedbackModal();
  });

  return (
    <>
      <AddUserInfoModal
        show={isUserInfoModalOpen}
        onHide={closeUserInfoModal}
        onSuccess={async () => {
          await useSubmissionVote.mutateAsync();
          closeUserInfoModal();
        }}
        title="Let's verify your vote!"
        footerProps={{
          confirmText: "Confirm Vote",
        }}
      />

      <FeedbackPromptModal
        show={isFeedbackModalOpen}
        onHide={closeFeedbackModal}
        submissionId={submission.id}
        designer={submission.user}
      />

      <Button
        color="success"
        size="lg"
        startContent={showVotes ? <VerifiedOutlinedIcon ref={animateVerified} /> : <VoteIcon />}
        className="sm:px-8 mr-2 gap-2 uppercase"
        onClick={handleOnClick}
        isLoading={useSubmissionVote.isPending || isUserInfoModalOpen}
        data-testid="vote-button"
        spinnerPlacement="end"
      >
        {showVotes ? (
          <span ref={animateVotes} data-testid="vote-count">
            {abbreviateNumber(submission?.votes, 1)}
          </span>
        ) : (
          "Vote"
        )}
      </Button>
    </>
  );
}
SubmissionVoteButton.propTypes = {
  submission: submissionPropTypes.isRequired,
  trackingLocation: PropTypes.string,
};
