import { useMemo } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PropTypes from "prop-types";

const arrowAnimations = {
  hoverPulse: "old-btn-hover-pulse",
  hoverColor: "old-btn-hover-bg",
  hoverPrev: "old-btn-hover-prev",
};

export default function IconButton({
  icon,
  direction = "next",
  hoverPulse = true,
  hoverColor = false,
  variant = "",
  className = "",
  iconClassName = "",
  ...props
}) {
  const FirstIcon = Array.isArray(icon) && icon.length >= 1 ? icon[0] : icon;
  const SecondIcon = Array.isArray(icon) && icon.length >= 2 ? icon[1] : icon;

  const animations = useMemo(() => {
    const allAnimations = [];

    if (hoverPulse) allAnimations.push(arrowAnimations.hoverPulse);

    if (hoverColor) allAnimations.push(arrowAnimations.hoverColor);

    if (variant) allAnimations.push([arrowAnimations.hoverColor, variant].join("-"));

    if (direction === "prev") allAnimations.push(arrowAnimations.hoverPrev);

    return allAnimations.join(" ");
  }, []);

  return (
    <div
      className={`flex items-center overflow-hidden ${animations} ${className}`}
      role="button"
      {...props}
    >
      <FirstIcon className={`!w-full !h-full ${iconClassName}`} />
      <SecondIcon className={`!w-full !h-full ${iconClassName}`} />
    </div>
  );
}
IconButton.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.elementType, PropTypes.arrayOf(PropTypes.elementType)])
    .isRequired,
  direction: PropTypes.oneOf(["next", "prev"]),
  hoverPulse: PropTypes.bool,
  hoverColor: PropTypes.bool,
  variant: PropTypes.string,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
};

IconButton.Arrow = function (props) {
  return <IconButton icon={ArrowForwardIcon} {...props} />;
};
IconButton.ArrowPrev = function (props) {
  return <IconButton icon={ArrowBackIcon} direction="prev" {...props} />;
};
