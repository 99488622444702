import { Group } from "@visx/group";
import { useParentSize } from "@visx/responsive";
import { scaleBand, scaleLinear } from "@visx/scale";
import { Text } from "@visx/text";
import { defaultStyles, Tooltip, useTooltip } from "@visx/tooltip";
import { motion } from "framer-motion";
import PropTypes from "prop-types";

const fontSize = "1em";
const barColor = "fill-default-300 hover:fill-default-400";
const transition = { duration: 0.6, ease: "easeInOut" };
const padding = 0.2;
const mr = 35;
const ml = 20;

export default function HorizontalBarChart({ data }) {
  const { parentRef, width, height } = useParentSize({
    debounceTime: 150,
    width: 500,
    height: 500,
  });
  const { tooltipOpen, tooltipData, tooltipLeft, tooltipTop, showTooltip, hideTooltip } =
    useTooltip();
  const xScale = scaleLinear({
    domain: [0, Math.max(...data.map((d) => d.value))],
    range: [ml, width - ml - mr],
  });
  const yScale = scaleBand({
    domain: data.map((d) => d.label),
    paddingInner: padding,
    range: [0, height],
  });

  return (
    <div ref={parentRef} className="relative size-full">
      <svg width={width} height={height} className="cursor-default">
        <Group left={ml}>
          {data.map((d) => {
            const barHeight = yScale.bandwidth();
            const barWidth = Math.max(0, xScale(d.value));
            const barY = yScale(d.label);
            const textY = barY + barHeight * 0.5;

            return (
              <motion.g
                key={`bar-${d.label}`}
                onMouseLeave={hideTooltip}
                onClick={() =>
                  showTooltip({
                    tooltipData: d.tooltip,
                    tooltipTop: textY,
                    tooltipLeft: 0,
                  })
                }
                onMouseEnter={() =>
                  showTooltip({
                    tooltipData: d.tooltip,
                    tooltipTop: textY,
                    tooltipLeft: 0,
                  })
                }
                whileTap={{ scale: 0.98 }}
                className="focus:outline-none"
              >
                <Text
                  x={-ml}
                  y={barY + barHeight * 0.5}
                  fontSize={fontSize}
                  textAnchor="start"
                  verticalAnchor="middle"
                >
                  {d.label}
                </Text>

                <motion.rect
                  x={0}
                  y={barY}
                  rx={10}
                  ry={10}
                  width={0}
                  height={barHeight}
                  initial={{ width: 0 }}
                  animate={{ width: barWidth }}
                  transition={transition}
                  className={barColor}
                />

                <motion.g initial={{ x: 0 }} animate={{ x: barWidth + 5 }} transition={transition}>
                  <Text
                    x={0}
                    y={barY + barHeight * 0.5}
                    fontSize={fontSize}
                    textAnchor="start"
                    verticalAnchor="middle"
                  >
                    {d.display}
                  </Text>
                </motion.g>
              </motion.g>
            );
          })}
        </Group>
      </svg>

      {tooltipOpen && (
        <Tooltip left={tooltipLeft} top={tooltipTop} style={defaultStyles}>
          {tooltipData}
        </Tooltip>
      )}
    </div>
  );
}
HorizontalBarChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      display: PropTypes.string.isRequired,
    }),
  ).isRequired,
};
