import { forwardRef, useState } from "react";
import PropTypes from "prop-types";

const ModalClickContainer = forwardRef(
  ({ modal, onOpenModal, modalProps, as, children, ...props }, ref) => {
    const [show, setShow] = useState(false);
    const Modal = modal;
    const As = as;

    const handleOpenModal = (e) => {
      if (show) return;

      e.stopPropagation();
      e.preventDefault();

      if (onOpenModal) onOpenModal(() => setShow(true));
      else setShow(true);
    };

    return (
      <>
        <Modal show={show} onHide={() => setShow(false)} {...modalProps} />

        <As {...props} onClick={handleOpenModal} ref={ref} role="button" tabIndex={0}>
          {children}
        </As>
      </>
    );
  },
);
ModalClickContainer.propTypes = {
  modal: PropTypes.elementType.isRequired,
  onOpenModal: PropTypes.func,
  modalProps: PropTypes.shape({}),
  as: PropTypes.elementType,
  children: PropTypes.node.isRequired,
};
ModalClickContainer.defaultProps = {
  onOpenModal: undefined,
  modalProps: {},
  as: "div",
};

export default ModalClickContainer;
