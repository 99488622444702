import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import { generatePath as domGeneratePath } from "react-router-dom";

dayjs.extend(relativeTime);
dayjs.extend(utc);

/*
    ------------------------------------------------------------------------
    An Assortment of helper functions for use throughout the application(s).
    ------------------------------------------------------------------------
*/

/*
    Function:   abbreviateNumber
    desc:       abbreviate a number to a fixed number of decimal points

    params:
    number:     int - the number to abbreviate
    decimals:   int - the number of decimal points to abbreviate to
*/
const abbreviateNumber = (number, decimals) => {
  if (number === undefined) return 0;
  if (number < 1000) return number;

  let x = `${number}`.length;
  decimals = 10 ** decimals;
  x -= x % 3;
  return Math.round(number * (decimals / 10 ** x)) / decimals + " kMGTPE"[x / 3];
};

const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

const objectUrlToFile = async (objecturl, fileName) => {
  const blob = await (await fetch(objecturl)).blob();
  return new File([blob], fileName, { type: blob.type });
};

const getNotificationPreferences = (formData, notificationPreferences) => {
  const newNotificationPreferences = {};
  Object.keys(notificationPreferences).forEach((key) => {
    newNotificationPreferences[key] = Boolean(formData[key]);
    delete formData[key];
  });
  return newNotificationPreferences;
};

const getFileProps = (file) =>
  new Promise((resolve, reject) => {
    if (file.size <= 0) reject(new Error("file not found"));

    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const thumbnail = new Image();

      thumbnail.addEventListener("load", () => resolve(thumbnail));

      thumbnail.src = reader.result;
    });
    reader.addEventListener("error", (e) => reject(e));
    reader.readAsDataURL(file);
  });

const writeFilesToInput = (files, input) => {
  if (!input || files.length <= 0) return;

  const list = new DataTransfer();
  files.forEach((file) => {
    if (!(file instanceof File)) return;
    list.items.add(file);
  });
  input.files = list.files;
};

// Override react-router-dom generate path to allow for matching wildcard cases
const generatePath = (path, match) => {
  if (match && "wildcard" in match) path = path.replace("*", match.wildcard);
  return domGeneratePath(path, match);
};

const redirectDocument = (url, params = {}) => {
  const splat = params["*"];
  const redirectUrl = splat ? [url, splat].join("/") : url; // Only append splat if present
  window.location.replace(redirectUrl + window.location.search);
  return null;
};

const stringifyFormData = (formData, ignoreKeys = []) => {
  const shouldStringify = (key) => !ignoreKeys.includes(key.toString());
  return Object.fromEntries(
    Object.entries(formData).map(([k, v]) => [k, shouldStringify(k) ? JSON.stringify(v) : v]),
  );
};

/**
 * TODO: in the future we want to make use of MSW v2.0 and the request.formData() function
 * - This is currently not possible as MSW v2.0 and undici v6/v5 are not compatable
 *
 * This is a workaround to parse the formdata stream and return an object of { key: value } pairs.
 * All values will be in string format but can be parsed where used locally.
 *
 * hours wasted here: 10 <-- please update if you try to use msw instead.
 */
const decodeArrayBufferFormData = (formData) => {
  formData = formData.filter((s) => typeof s !== "function");
  const mappings = {};

  formData.forEach((value, index) => {
    if (index % 2 === 0) return;

    const key = formData[index - 1]
      .split("name=")[1]
      .replace(/(\r\n|\n|\r)/gm, "")
      .replace(/['"]+/g, "");

    try {
      mappings[key] = JSON.parse(value);
    } catch (err) {
      mappings[key] = value.replace(/['"]+/g, "");
    }
  });
  return mappings;
};

const calculateDaysLeft = (deadline) => {
  const deadlineTime = dayjs(deadline);
  const currentTime = dayjs();
  if (currentTime > deadlineTime) return null;

  const daysLeft = deadlineTime.diff(currentTime, "day");
  if (daysLeft === 0) return "Last Day";
  if (daysLeft > 0 && daysLeft < 6) return `${daysLeft} ${daysLeft > 1 ? "days" : "day"}`;
  return null;
};

function formatRelativeTime(timestamp) {
  const postTime = dayjs.utc(timestamp);
  return postTime.fromNow();
}

// https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
function mobileAndTabletCheck() {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a,
      ) ||
      // eslint-disable-next-line no-useless-escape
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}

export {
  abbreviateNumber,
  formatBytes,
  objectUrlToFile,
  getNotificationPreferences,
  getFileProps,
  writeFilesToInput,
  generatePath,
  redirectDocument,
  stringifyFormData,
  decodeArrayBufferFormData,
  calculateDaysLeft,
  formatRelativeTime,
  mobileAndTabletCheck,
};
