import { useContext, useEffect, useMemo, useState } from "react";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import PublicIcon from "@mui/icons-material/Public";
import PropTypes from "prop-types";
import { generatePath, Link, useLocation, useNavigate } from "react-router-dom";

import { BriefContext, briefStatusContent } from "features/brief";
import {
  proposalStatusContent,
  SubmissionCard,
  submissionListPropTypes,
  submissionStatusContent,
  useSubmissions,
} from "features/submission";
import { ShareButton, UserContext } from "features/user";

import { Accordion, AutoScrollLoader, Button, Col, Progress, Row } from "ui";

import useWindowSize from "hooks/useWindowSize";

import routes from "default/routes";

const REQUIREDVOTES = 20;

const cardSizing = {
  md: 6,
  lg: 4,
  xl: 6,
  xxxl: 4,
};

function CardGrid({ submissions, submissionStatus }) {
  const navigate = useNavigate();
  const { brief } = useContext(BriefContext);
  const { callToAction } = briefStatusContent(brief)[brief.status];
  const Icon = submissionStatus.icon;

  const showStatus = () => {
    if (!submissionStatus.isDisabled) return true;
    if (submissions.all.length > 0) return false;
    return true;
  };

  return (
    <>
      <Row>
        {submissions.all.map((entry) => (
          <Col key={entry.id} className="my-4" {...cardSizing}>
            <div className="bg-content1 rounded-3xl">
              <SubmissionCard
                entry={entry}
                onClick={(submission) =>
                  navigate(
                    generatePath(routes.briefSubmission, {
                      id: brief.id,
                      submissionId: submission.id,
                    }),
                    {
                      state: {
                        scrollToTop: false,
                      },
                    },
                  )
                }
              />

              {entry.published && entry.type === "submission" && entry.votes < REQUIREDVOTES && (
                <div className="p-4">
                  <Progress
                    value={(entry.votes / REQUIREDVOTES) * 100}
                    label={`${entry.votes} / ${REQUIREDVOTES} Votes`}
                    showValueLabel
                    classNames={{
                      label: "text-sm font-bold",
                      value: "text-sm font-bold",
                      base: "gap-1",
                    }}
                  />
                </div>
              )}
            </div>
          </Col>
        ))}

        {!submissions.isLoading && showStatus() && (
          <Col {...cardSizing} className="my-4">
            <Button
              className="w-full h-full rounded-3xl bg-content1 shadow-lg aspect-4/3"
              as={Link}
              to={callToAction?.path}
              isDisabled={submissionStatus.isDisabled ?? true}
            >
              <div className="flex flex-col justify-center items-center text-center">
                <Icon className="!size-24 text-primary-500 mb-2" />
                <span className="text-lg text-wrap">{submissionStatus.header}</span>
                <span className="normal-case font-roman text-wrap">{submissionStatus.text}</span>
              </div>
            </Button>
          </Col>
        )}
      </Row>

      <AutoScrollLoader
        fetchNextPage={submissions.fetchNextPage}
        hasNextPage={submissions.hasNextPage}
        isLoading={submissions.isLoading || submissions.isFetchingNextPage}
        error={submissions.error}
        empty={!submissions.all.length}
        className="grow flex my-0"
      />
    </>
  );
}
CardGrid.propTypes = {
  submissions: submissionListPropTypes.isRequired,
  submissionStatus: PropTypes.shape({
    icon: PropTypes.elementType.isRequired,
    text: PropTypes.string.isRequired,
    header: PropTypes.string,
    isDisabled: PropTypes.bool.isRequired,
  }).isRequired,
};

export default function BriefWorkspace() {
  const { brief } = useContext(BriefContext);
  const { user } = useContext(UserContext);
  const [width] = useWindowSize();
  const [selectedKeys, setSelectedKeys] = useState(new Set([]));
  const { state } = useLocation();

  // Automatically open the correct accordions
  useEffect(() => {
    if (width >= 768) {
      setSelectedKeys([
        ...(["proposing", "selecting"].includes(brief.status) ? ["proposals"] : []),
        "submissions",
        "next",
      ]);
    } else if (state?.postSubmission) {
      setSelectedKeys(["next"]);
    } else {
      setSelectedKeys([]);
    }
    // We omit state since when we close the shareSubmissionModal we want to keep the accordion open
  }, [width, brief.status]);

  const submissions = useSubmissions({
    user_id: user.username,
    brief_id: brief.id,
    type: ["submission"],
    sort: "newest",
  });
  const proposals = useSubmissions({
    user_id: user.username,
    brief_id: brief.id,
    type: ["proposal"],
    sort: "newest",
  });

  const submissionStatus = useMemo(() => {
    if (brief.type === "private") {
      if (proposals.all.length <= 0 || ["proposing", "selecting"].includes(brief.status))
        return "noProposal";
      if (!proposals.all[0].is_winner) return "locked";
    }
    if (submissions.all.length <= 0 && ["voting", "judging"].includes(brief.status))
      return "noSubmission";
    return brief.status;
  }, [proposals.all, submissions.all]);

  const shareSubmission =
    submissions.all.filter((s) => s.published).length > 0
      ? submissions.all.filter((s) => s.published)[0]
      : {};

  return (
    <div data-testid="brief-workspace" className="relative bg-content2 rounded-3xl p-3">
      <Accordion
        variant="splitted"
        className="gap-3 px-0"
        selectionMode="multiple"
        selectedKeys={selectedKeys}
        onSelectionChange={setSelectedKeys}
        itemClasses={{
          base: "shadow-none bg-content2",
          title: "text-2xl",
          subtitle: "font-roman ",
        }}
      >
        {brief.type === "private" && (
          <Accordion.AccordionItem
            key="proposals"
            title="Your Proposals"
            subtitle="Manage your proposals"
          >
            <CardGrid
              submissions={proposals}
              submissionStatus={
                proposalStatusContent()[brief.status !== "proposing" ? "noProposal" : brief.status]
              }
            />
          </Accordion.AccordionItem>
        )}

        <Accordion.AccordionItem
          key="submissions"
          title="Your Submissions"
          subtitle="Manage your submissions"
        >
          <CardGrid
            submissions={submissions}
            submissionStatus={submissionStatusContent()[submissionStatus]}
          />
        </Accordion.AccordionItem>

        {Object.keys(shareSubmission).length > 0 && (
          <Accordion.AccordionItem
            key="next"
            title="Next Steps"
            subtitle="How to get the most votes!"
          >
            <Row className="gap-y-4">
              <Col xs={12} md={6}>
                <div className="bg-content1 rounded-3xl p-6 flex flex-col gap-4 h-full">
                  <span className="uppercase flex items-center gap-2">
                    <PublicIcon /> Share your work
                  </span>
                  <p className="mb-0">Share your work now and get supported early.</p>

                  <ShareButton
                    title=""
                    trackingLocation="submission body"
                    url={generatePath(routes.userProfileSubmission, {
                      username: shareSubmission.user.username,
                      submissionId: shareSubmission.id,
                    })}
                    direction="horizontal"
                    color="primary"
                    tooltipProps={{
                      placement: "bottom",
                    }}
                    classNames={{
                      base: "w-11 h-11",
                      icon: "!size-6",
                      wrapper: "justify-around",
                    }}
                  />

                  <small className="font-roman">
                    Submissions with 20 votes have a chance to be spotlighed and featured on the
                    Oditi Instagram!
                  </small>
                </div>
              </Col>

              <Col xs={12} md={6}>
                <div className="bg-content1 rounded-3xl p-6 flex flex-col gap-4 h-full">
                  <span className="uppercase flex items-center gap-2">
                    <NotificationsNoneIcon /> Keep up to date
                  </span>
                  <p className="mb-0">
                    Follow us on Instagram for all important updates relating to this brief.
                  </p>

                  <Button
                    color="primary"
                    as={Link}
                    to="https://www.instagram.com/_oditi?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                  >
                    Follow Us
                  </Button>

                  <small className="font-roman">
                    (Don&apos;t worry - you can unfollow us later!)
                  </small>
                </div>
              </Col>
            </Row>
          </Accordion.AccordionItem>
        )}
      </Accordion>
    </div>
  );
}
