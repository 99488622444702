import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";
import { tv } from "tailwind-variants";

import { Tooltip } from "ui";

const submissionBadge = tv({
  base: "p-4 rounded-full shadow-2xl",
  variants: {
    type: {
      winner: "bg-gold text-primary-roeground old-shine-horizontal",
      pending: "bg-primary text-primary-foreground",
      failed: "bg-danger text-primary-foreground",
      approved: "bg-success text-primary-foreground",
    },
  },
});

const badges = {
  winner: {
    component: <EmojiEventsIcon data-testid="submission-badge-winner" />,
    tooltip: "Brief Winner",
  },
  pending: {
    component: <HourglassBottomIcon data-testid="submission-badge-pending" />,
    tooltip: "Pending",
  },
  failed: {
    component: <CloseIcon data-testid="submission-badge-not-approved" />,
    tooltip: "Proposal Not Selected",
  },
  approved: {
    component: <DoneIcon data-testid="submission-badge-approved" />,
    tooltip: "Proposal Selected",
  },
};

export default function SubmissionBadge({ type, className = "" }) {
  const BadgeComponent = badges[type].component;
  const badgeTooltip = badges[type].tooltip;

  return (
    <Tooltip content={badgeTooltip} placement="bottom">
      <span className={twMerge(submissionBadge({ type }), className)}>{BadgeComponent}</span>
    </Tooltip>
  );
}
SubmissionBadge.propTypes = {
  type: PropTypes.oneOf(["winner", "pending", "failed", "approved"]).isRequired,
  className: PropTypes.string,
};
