import { generatePath } from "react-router-dom";

import { calculateDaysLeft } from "utils/helpers";

import routes from "default/routes";

// Helper to create CTA object
const createCallToAction = (text, path, trackingName) => ({
  text,
  path,
  trackingName,
});

const briefStatusContent = (brief) => ({
  scheduled: {
    status: "Brief Goes Live In:",
    title: "Brief Not Live",
    reminder: `${calculateDaysLeft(brief.start_time)} to start`,
    description:
      "Check back after the start time to enter! In the meantime you can browse other briefs!",
    callToAction: createCallToAction("Browse Other Briefs", routes.briefs, "view briefs"),
    deadline: "start_time",
    instructions: {
      submission: [
        false,
        "You cannot currently make submissions to this brief. Submissions will open once the brief starts.",
      ],
      visibility: [false, "This brief will not be public until after the start time."],
      voting: [false, "Voting and sharing will only be enabled after the brief starts."],
    },
  },
  proposing: {
    status: "Proposal Deadline Closes In:",
    title: "Submit Your Proposal",
    reminder:
      calculateDaysLeft(brief.proposal_deadline) === null
        ? ""
        : `${calculateDaysLeft(brief.proposal_deadline)} to submit!`,
    description:
      "Based on the brief details, submit a proposal detailing your vision to the client. Try and include as much detail as possible to convince the client to move forward with your idea!",
    callToAction: createCallToAction(
      "Submit Proposal",
      generatePath(routes.enterBrief, { id: brief.id }),
      "view enter brief",
    ),
    deadline: "proposal_deadline",
    instructions: {
      submission: [
        true,
        "You can make multiple proposals to this brief before the proposal deadline. Remember, quality over quantity!",
      ],
      visibility: [
        false,
        "Your proposals will only be visible to you and the client - other participants will not see your work.",
      ],
      voting: [
        false,
        "Private briefs are not public - only you and the organisation will be able to access this brief.",
      ],
    },
  },
  selecting: {
    status: "Proposals Selected In:",
    title: "Proposals Now Closed",
    description:
      "Thank you for all your proposals! The client is now carefully considering which ideas to take forward - if selected you will receive an initial fee and be invited to submit your full idea!",
    deadline: "proposal_selected",
    instructions: {
      submission: [
        false,
        "The client is currently the deciding outcome of all proposals and is not currently accepting new submissions.",
      ],
      visibility: [
        false,
        "Your proposals will only be visible to you and the client - other participants will not see your work.",
      ],
      voting: [
        false,
        "Private briefs are not public - only you and the organisation will be able to access this brief.",
      ],
    },
  },
  get submitting() {
    const status = {
      status: "Submission Deadline Closes In:",
      deadline: "submission_deadline",
      reminder:
        calculateDaysLeft(brief.submission_deadline) === null
          ? ""
          : `${calculateDaysLeft(brief.submission_deadline)} to submit!`,
      instructions: {
        submission: [
          true,
          "You can make multiple submissions to this brief before the deadline. Remember, quality over quantity!",
        ],
        ...(brief.type !== "challenge"
          ? {
              visibility: [
                false,
                "Your submissions are private until the deadline, so only you and the people you share them with can see them for now.",
              ],
            }
          : {
              visibility: [
                true,
                "You can see all published submissions on the voting and submission pages!",
              ],
            }),
        voting: [
          true,
          "You can share a direct link to your submission to get early feedback and votes from friends and followers on social media.",
        ],
      },
    };
    if (!brief.can_submit) {
      return {
        ...status,
        callToAction: {},
        title: "Thanks for Proposing!",
        description:
          "Unfortunately your proposal was not approved which means you cannot enter this brief. Make sure to try again on the next brief!",
      };
    }
    return {
      ...status,
      title: "Give Your Idea A Chance",
      description:
        "Read through the brief details, come up with an idea, and execute it to the best of your ability!",
      callToAction: createCallToAction(
        "Create Submission",
        generatePath(routes.enterBrief, { id: brief.id }),
        "view enter brief",
      ),
    };
  },
  voting: {
    status: "Voting Deadline Closes In:",
    title: "Cast Your Votes Now",
    reminder:
      calculateDaysLeft(brief.voting_deadline) === null
        ? ""
        : `${calculateDaysLeft(brief.voting_deadline)} to vote!`,
    description:
      "Your vote matters! 🗳️ Submissions with the most votes have a higher chance of being selected as a winner by the judges 🌟",
    deadline: "voting_deadline",
    instructions: {
      submission: [false, "Submissions are closed now that the deadline has passed."],
      visibility: [
        true,
        "You can see all published submissions here or on the voting and submission pages!",
      ],
      voting: [true, "Voting is open! Show support by voting for your favorite designs."],
    },
  },
  judging: {
    status: "Winners Selected In:",
    title: "Selection In Progress",
    description:
      "Submissions will be presented to the selectors in order of votes received before the deadline.",
    deadline: "winners_selected",
    instructions: {
      submission: [false, "Submissions are closed now that the deadline has passed"],
      visibility: [
        true,
        "You can see all published submissions here or on the voting and submission pages!",
      ],
      voting: [
        true,
        "Selection will not be impacted by new votes, but you can still vote to support your favourite designers!",
      ],
    },
  },
  completed: {
    status: "Accepting New Submissions",
    title: "Winner Announced!",
    description:
      "Now that the winners have been selected this brief is back open! It is the perfect time to get inspired and make a submission of your own.",
    callToAction: createCallToAction(
      "Create Submission",
      generatePath(routes.enterBrief, { id: brief.id }),
      "view enter brief",
    ),
    instructions: {
      submission: [
        true,
        "The competition has ended, but you are welcome to submit a new idea if you feel inspired!",
      ],
      visibility: [
        true,
        "You can see all published submissions here or on the voting and submission pages!",
      ],
      voting: [true, "Show your support by voting and leaving feedback to our amazing creators!"],
    },
  },
});

export { briefStatusContent };
