import { useRef } from "react";
import BrushIcon from "@mui/icons-material/Brush";
import CategoryIcon from "@mui/icons-material/Category";
import CheckroomIcon from "@mui/icons-material/Checkroom";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import FactoryIcon from "@mui/icons-material/Factory";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import GestureIcon from "@mui/icons-material/Gesture";
import LabelIcon from "@mui/icons-material/Label";
import PaletteIcon from "@mui/icons-material/Palette";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import PropTypes from "prop-types";

import { Button, Chip, Tab, Tabs } from "ui";

import useScroll from "hooks/useScroll";

function ChallengesIcon(props) {
  if (new Date() > new Date("2024-12-01")) return <FitnessCenterIcon {...props} />;

  // TODO: remove this when the date has passed
  return (
    <Chip color="success" className="mr-2">
      New!
    </Chip>
  );
}

const categories = {
  all: { label: "All Briefs", icon: CategoryIcon },
  ...(process.env.REACT_APP_ENABLE_CHALLENGES === "true"
    ? { challenges: { label: "Challenges", icon: ChallengesIcon } }
    : {}),
  graphic_design: { label: "Graphic Design", icon: BrushIcon },
  illustration: { label: "Illustration", icon: GestureIcon },
  branding: { label: "Branding", icon: LabelIcon },
  fashion: { label: "Fashion", icon: CheckroomIcon },
  art: { label: "Art", icon: PaletteIcon },
  industrial: { label: "Industrial", icon: FactoryIcon },
  typography: { label: "Typography", icon: TextFieldsIcon },
};

export default function CategoryToggles({ handleSelectCategory, defaultSelectedKey = "all" }) {
  const scrollRef = useRef(null);
  const { atEnd, atStart, scrollRight, scrollLeft } = useScroll(scrollRef);

  return (
    <div className="relative flex justify-center items-center">
      <Tabs
        ref={scrollRef}
        size="lg"
        radius="lg"
        color="primary"
        items={Object.entries(categories)}
        onSelectionChange={handleSelectCategory}
        selectedKey={defaultSelectedKey}
        fullWidth
        classNames={{
          base: "max-w-full flex justify-center",
          tab: "uppercase h-auto sm:py-2",
          tabList: "p-2",
          cursor: "rounded-2xl",
        }}
      >
        {([category, { label, icon: Icon }]) => (
          <Tab
            key={category}
            title={
              <div className="flex items-center p-2">
                <Icon className="!size-5 mr-2" />
                <span>{label}</span>
              </div>
            }
          />
        )}
      </Tabs>

      <Button
        isIconOnly
        size="sm"
        color="primary"
        variant="light"
        className={`absolute left-2 h-2/3 backdrop-blur-sm touch-device:invisible ${atStart && "hidden"}`}
        onClick={() => scrollLeft()}
      >
        <ChevronLeft color="inherit" />
      </Button>
      <Button
        isIconOnly
        size="sm"
        color="primary"
        variant="light"
        className={`absolute right-2 h-2/3 backdrop-blur-sm touch-device:invisible ${atEnd && "hidden"}`}
        onClick={() => scrollRight()}
      >
        <ChevronRight />
      </Button>
    </div>
  );
}
CategoryToggles.propTypes = {
  handleSelectCategory: PropTypes.func.isRequired,
  defaultSelectedKey: PropTypes.string,
};
