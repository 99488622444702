import { useSearchParams } from "react-router-dom";

import { BriefCard, ChallengeCard, useBriefs } from "features/brief";
import { PageLayout } from "features/layout";
import { SubmissionVoteBlock } from "features/submission";

import { AutoScrollLoader, CardGrid, Col, Container, Row, ScrollToTopButton } from "ui";

import useWindowSize from "hooks/useWindowSize";

import BriefSwiper from "./BriefSwiper";
import CategoryToggles from "./CategoryToggles";
import ChallengeSwiper from "./ChallengeSwiper";

const breakForChallenges = (width) => {
  if (width < 768) return 1;
  if (width >= 768 && width < 1200) return 2;
  if (width >= 1200 && width < 1600) return 3;
  return 4;
};

export default function Briefs() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [width] = useWindowSize();
  const isChallengesTab = searchParams.get("category") === "challenges";
  const normalBriefs = useBriefs({
    status: null,
    search: searchParams.get("search") || "",
    type: ["public", "private"],
    design_interests: searchParams.get("category") || [],
  });
  const challengeBriefs = useBriefs({
    status: !searchParams.get("category") ? ["submitting", "voting"] : null,
    type: ["challenge"],
    design_interests: !isChallengesTab ? searchParams.get("category") || [] : [],
  });
  const briefs = isChallengesTab ? challengeBriefs : normalBriefs;
  const Card = isChallengesTab ? ChallengeCard : BriefCard;

  return (
    <PageLayout
      pageName="Briefs"
      showFooter={!briefs.hasNextPage}
      navbarProps={{
        size: "lg",
      }}
    >
      <Container id="briefs" className="after:pb-12 after:block overflow-hidden">
        <BriefSwiper />

        <CategoryToggles
          handleSelectCategory={(id) => setSearchParams(id !== "all" ? { category: id } : {})}
          defaultSelectedKey={searchParams.get("category") || "all"}
        />

        <Row>
          <Col className="overflow-hidden">
            <CardGrid
              entries={briefs.all.slice(0, breakForChallenges(width))}
              card={Card}
              className="mb-0"
              trackingLocation="briefs grid"
            />

            {!isChallengesTab && challengeBriefs.all.length > 0 && !briefs.isLoading && (
              <ChallengeSwiper
                challenges={challengeBriefs}
                onViewAll={() => setSearchParams({ category: "challenges" })}
              />
            )}

            <CardGrid
              entries={briefs.all.slice(breakForChallenges(width), 12)}
              card={Card}
              trackingLocation="briefs grid"
            />

            {briefs.all.length > 8 && (
              <Container className="bg-content2 rounded-3xl mb-4 py-4 md:py-12">
                <SubmissionVoteBlock color="primary" />
              </Container>
            )}

            <CardGrid entries={briefs.all.slice(12)} card={Card} trackingLocation="briefs grid" />
          </Col>
        </Row>

        <AutoScrollLoader
          fetchNextPage={briefs.fetchNextPage}
          hasNextPage={briefs.hasNextPage}
          isLoading={briefs.isLoading || briefs.isFetchingNextPage}
          error={briefs.error}
          empty={!briefs.all.length}
        >
          There are no briefs here at the moment.
          <br />
          Please come back at a later date.
        </AutoScrollLoader>
      </Container>

      <ScrollToTopButton />
    </PageLayout>
  );
}
