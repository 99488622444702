import "css/index.css";

import React from "react";
import { NextUIProvider } from "@nextui-org/react";
import ReactDOM from "react-dom/client";
import { SuperTokensWrapper } from "supertokens-auth-react";

import { QueryProvider } from "api";

const App =
  process.env.REACT_APP_TYPE === "org"
    ? require("./apps/org/App").default
    : require("./apps/default/App").default;

const root = ReactDOM.createRoot(document.getElementById("root"));

const content = (
  <SuperTokensWrapper>
    <NextUIProvider locale="en-GB">
      <QueryProvider>
        <App />
      </QueryProvider>
    </NextUIProvider>
  </SuperTokensWrapper>
);

if (!process.env.REACT_APP_STRICT_MODE || process.env.REACT_APP_STRICT_MODE === "false")
  root.render(content);
else root.render(<React.StrictMode>{content}</React.StrictMode>);
