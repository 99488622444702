import { client, useMutation, useQuery, useQueryClient } from "api";

import updateLikeState from "./helpers";

const feedbackQueries = {
  list: (id) => [{ url: `submissions/${id}/feedback/`, view: "list" }],
};

const useFeedback = (submissionId) =>
  useQuery({
    queryKey: feedbackQueries.list(submissionId),
    queryFn: async ({ queryKey: [{ url }] }) => {
      const response = await client.get(url);
      return response.data;
    },
  });

const useFeedbackMutations = (submissionId) => {
  const queryClient = useQueryClient();
  return {
    createFeedback: useMutation({
      mutationFn: (feedbackData) =>
        client.post(`submissions/${submissionId}/feedback/`, feedbackData),
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: feedbackQueries.list(submissionId) });
      },
    }),
    deleteFeedback: useMutation({
      mutationFn: (commentId) => client.delete(`comments/${commentId}/`),
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: feedbackQueries.list(submissionId) });
      },
    }),
    likeFeedback: useMutation({
      mutationFn: (commentId) => client.put(`comments/${commentId}/like/`),
      onMutate: async (commentId) => {
        await queryClient.cancelQueries({ queryKey: feedbackQueries.list(submissionId) });
        const previousFeedback = queryClient.getQueryData(feedbackQueries.list(submissionId));
        queryClient.setQueryData(feedbackQueries.list(submissionId), (oldData) => ({
          ...oldData,
          comments: updateLikeState(oldData.comments, commentId, true),
        }));
        return { previousFeedback };
      },
      onError: (err, commentId, context) =>
        queryClient.setQueryData(feedbackQueries.list(submissionId), context.previousFeedback),
      onSettled: () =>
        queryClient.invalidateQueries({ queryKey: feedbackQueries.list(submissionId) }),
    }),
    unlikeFeedback: useMutation({
      mutationFn: (commentId) => client.delete(`comments/${commentId}/like/`),
      onMutate: async (commentId) => {
        await queryClient.cancelQueries({ queryKey: feedbackQueries.list(submissionId) });
        const previousFeedback = queryClient.getQueryData(feedbackQueries.list(submissionId));
        queryClient.setQueryData(feedbackQueries.list(submissionId), (oldData) => ({
          ...oldData,
          comments: updateLikeState(oldData.comments, commentId, false),
        }));
        return { previousFeedback };
      },
      onError: (err, commentId, context) =>
        queryClient.setQueryData(feedbackQueries.list(submissionId), context.previousFeedback),
      onSettled: () =>
        queryClient.invalidateQueries({ queryKey: feedbackQueries.list(submissionId) }),
    }),
    hideFeedback: useMutation({
      mutationFn: (commentId) => client.put(`comments/${commentId}/hide/`),
      onSuccess: async () =>
        queryClient.invalidateQueries({ queryKey: feedbackQueries.list(submissionId) }),
    }),
    unhideFeedback: useMutation({
      mutationFn: (commentId) => client.delete(`comments/${commentId}/hide/`),
      onSuccess: async () =>
        queryClient.invalidateQueries({ queryKey: feedbackQueries.list(submissionId) }),
    }),
  };
};

export { useFeedback, useFeedbackMutations };
