import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";
import { tv } from "tailwind-variants";

import Col from "ui/layout/Col";
import Row from "ui/layout/Row";

import useKeyGen from "hooks/useKeyGen";

const defaultSizing = {
  md: 6,
  xl: 4,
  xxxl: 3,
};

const cardGrid = tv({
  slots: {
    base: "mb-4",
    wrapper: "my-4 old-transition-slide-arrow",
  },
});

export default function CardGrid({
  card,
  entries = [],
  sizing = defaultSizing,
  className = "",
  classNames = {},
  ...cardProps
}) {
  const { base, wrapper } = cardGrid();
  const keyGen = useKeyGen();
  const Card = card;

  if (entries.length <= 0) return null;

  return (
    <div className={twMerge(base(), className, classNames.base)} data-testid="image-card-display">
      <Row>
        {entries.map((entry, index) => (
          <Col
            key={keyGen.getKey(entry)}
            {...sizing}
            className={twMerge(wrapper(), classNames.wrapper)}
          >
            <Card entry={entry} key={entry.id} id={index} {...cardProps} />
          </Col>
        ))}
      </Row>
    </div>
  );
}
CardGrid.propTypes = {
  card: PropTypes.elementType.isRequired,
  entries: PropTypes.arrayOf(PropTypes.shape({})),
  sizing: PropTypes.objectOf(PropTypes.number),
  className: PropTypes.string,
  classNames: PropTypes.shape({
    base: PropTypes.string,
    wrapper: PropTypes.string,
  }),
};
