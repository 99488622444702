import {
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Modal as NextModal,
} from "@nextui-org/react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import Button from "ui/buttons/Button";

function ModalActionsFooter({
  onCancel,
  onConfirm = undefined,
  cancelProps = {},
  confirmProps = {},
  cancelText = "Cancel",
  confirmText = "Confirm",
  className = "",
}) {
  return (
    <Modal.ModalFooter className={twMerge("justify-center", className)}>
      <Button onClick={onCancel} variant="faded" {...cancelProps}>
        {cancelText}
      </Button>

      <Button onClick={onConfirm} type="submit" color="primary" {...confirmProps}>
        {confirmText}
      </Button>
    </Modal.ModalFooter>
  );
}
ModalActionsFooter.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  cancelProps: PropTypes.shape({}),
  confirmProps: PropTypes.shape({}),
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  className: PropTypes.string,
};

function Modal({ children, ...props }) {
  return (
    // Manually specifying motion props to fix nested tab animations
    // see: https://github.com/nextui-org/nextui/issues/2297
    <NextModal
      motionProps={{
        variants: {
          enter: {
            scale: 1,
            y: "var(--slide-enter)",
            opacity: 1,
            transition: {
              scale: {
                duration: 0.4,
                ease: [0.36, 0.66, 0.4, 1],
              },
              opacity: {
                duration: 0.4,
                ease: [0.36, 0.66, 0.4, 1],
              },
              y: {
                type: "spring",
                bounce: 0,
                duration: 0.6,
              },
            },
          },
          exit: {
            scale: 1.1, // NextUI default 1.03
            y: "var(--slide-exit)",
            opacity: 0,
            transition: {
              duration: 0.3,
              ease: [0.36, 0.66, 0.4, 1],
            },
          },
        },
      }}
      {...props}
    >
      <ModalContent>{children}</ModalContent>
    </NextModal>
  );
}
Modal.propTypes = {
  children: PropTypes.node.isRequired,
};

Modal.ModalBody = ModalBody;
Modal.ModalFooter = ModalFooter;
Modal.ModalHeader = ModalHeader;
Modal.ModalActionsFooter = ModalActionsFooter;
export default Modal;
