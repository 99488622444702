import { useEffect, useState } from "react";

export default function useScrollAmount(threshold = 0.5) {
  const [scrollReached, setScrollReached] = useState(false);

  useEffect(() => {
    const handleScrollAmount = () => {
      const scrollPercent = window.innerHeight * threshold;

      if (window.pageYOffset > scrollPercent) setScrollReached(true);

      if (window.pageYOffset < scrollPercent) setScrollReached(false);
    };

    window.addEventListener("scroll", handleScrollAmount);
    return () => {
      window.removeEventListener("scroll", handleScrollAmount);
    };
  }, []);

  return scrollReached;
}
