import { useContext } from "react";

import BriefContext from "features/brief/context/BriefContext";

import { DateProgressBar } from "ui";

export default function BriefTimeline({ ...progressProps }) {
  const { brief } = useContext(BriefContext);

  if (brief.type === "private") {
    return (
      <>
        <DateProgressBar
          startDate={brief.start_time}
          endDate={brief.proposal_deadline}
          title="Make a Proposal"
          {...progressProps}
        />

        <DateProgressBar
          startDate={brief.proposal_selected}
          endDate={brief.submission_deadline}
          title="Submit Your Design"
          {...progressProps}
        />

        <DateProgressBar
          startDate={brief.submission_deadline}
          endDate={brief.winners_selected}
          title="Final Review"
          {...progressProps}
        />
      </>
    );
  }

  return (
    <>
      <DateProgressBar
        startDate={brief.start_time}
        endDate={brief.submission_deadline}
        title="Submit Your Design"
        {...progressProps}
      />

      <DateProgressBar
        startDate={brief.submission_deadline}
        endDate={brief.voting_deadline}
        title="Voting"
        {...progressProps}
      />
      <DateProgressBar
        startDate={brief.voting_deadline}
        endDate={brief.winners_selected}
        title="Winner Announced"
        {...progressProps}
      />
    </>
  );
}
