import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { useController } from "react-hook-form";

import { ProtectedModalClickContainer } from "features/authentication";
import { socialPrefixes } from "features/profile/data/socialData";

import Input from "forms/Input";
import { Button } from "ui";

import AddSocialModal from "./modal/AddSocialModal";

export default function ControlledSocialInput({
  name,
  control,
  socialName,
  icon,
  defaultValue = "",
}) {
  const {
    field: { onChange, value, ...field },
  } = useController({
    name,
    defaultValue,
    control,
  });
  const Icon = icon;

  return (
    <div className="bg-content2 rounded-3xl p-2 w-full flex justify-between items-center">
      <div className="flex items-center">
        <Icon className="!size-8 mr-2" />
        <span className="capitalize">{socialName}</span>
      </div>

      <Input value={value} {...field} className="hidden" />

      {!value ? (
        <ProtectedModalClickContainer
          modal={AddSocialModal}
          as={Button}
          color="success"
          size="sm"
          modalProps={{
            social: socialName,
            onComplete: (newValue) => onChange(socialPrefixes[socialName] + newValue),
          }}
        >
          Link
        </ProtectedModalClickContainer>
      ) : (
        <div>
          <span className="text-primary-300 me-4">
            {/^[^@]/.test(value.split("/")[3]) ? "@" : ""}
            {value.split("/")[3]}
          </span>

          <CloseIcon
            className="!size-8 rounded-full bg-content1 p-2"
            role="button"
            onClick={() => onChange("")}
          />
        </div>
      )}
    </div>
  );
}
ControlledSocialInput.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.shape({}).isRequired,
  socialName: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  defaultValue: PropTypes.string,
};
