import PropTypes from "prop-types";

import { briefListPropTypes, ChallengeCard } from "features/brief";

import { Button, Col, Row, Swiper } from "ui";

export default function ChallengeSwiper({ challenges, onViewAll }) {
  return (
    <div className="bg-content2 rounded-3xl py-4 md:py-12">
      <Row>
        <Col xs={12} lg={4} className="mb-6 lg:mb-0">
          <div className="pl-4 md:pl-16 pr-4 md:pr-16 lg:pr-0 inline-flex flex-col justify-around h-full">
            <h2 className="sm:leading-[70px] ">Beat The AI</h2>

            <p className="mt-2 mb-0 lg:mb-4">
              Got a spare 60 minutes? Try these quick creative challenges to test your design skills
              against an AI.
            </p>

            <Button
              color="primary"
              size="lg"
              className="hidden lg:flex w-min"
              onClick={onViewAll}
              trackingName="view challenges"
              trackingLocation="challenge swiper"
            >
              View All
            </Button>
          </div>
        </Col>

        <Col xs={12} lg={8} className="h-full">
          <Swiper
            classNames={{
              base: "my-0",
              wrapper: "px-4 md:px-16 lg:px-6",
              leftGradient: "touch-device:w-5 touch-device:sm:w-auto",
              rightGradient: "touch-device:w-5 touch-device:sm:w-auto",
            }}
          >
            {challenges.all.map((challenge) => (
              <ChallengeCard
                entry={challenge}
                className="min-w-72 max-w-72 sm:min-w-96 sm:max-w-96"
                trackingLocation="challenge swiper"
              />
            ))}
          </Swiper>
        </Col>
      </Row>
    </div>
  );
}
ChallengeSwiper.propTypes = {
  challenges: briefListPropTypes.isRequired,
  onViewAll: PropTypes.func.isRequired,
};
