import { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { generatePath, useNavigate } from "react-router-dom";

import { BriefContext } from "features/brief";
import {
  SubmissionCard as DefaultSubmissionCard,
  useSubmissions,
  withViewCounter,
} from "features/submission";

import { AutoScrollLoader, CardGrid } from "ui";

import routes from "default/routes";

const SubmissionCard = withViewCounter(DefaultSubmissionCard);

export default function BriefSubmissions({ setFinalPage }) {
  const { brief } = useContext(BriefContext);
  const navigate = useNavigate();

  const submissions = useSubmissions({
    brief_id: brief.id,
    published: true,
    sort: "ucb",
    winners: brief.status === "completed" ? false : null,
  });

  const { all: winners } = useSubmissions({
    brief_id: brief.id,
    winners: true,
    page_size: 100,
  });

  useEffect(() => setFinalPage(!submissions.hasNextPage), [submissions.hasNextPage]);

  return (
    <div data-testid="brief-submissions">
      <CardGrid
        entries={[...winners, ...submissions.all]}
        card={SubmissionCard}
        onClick={(submission) =>
          navigate(
            `${generatePath(routes.briefSubmission, { id: brief.id, submissionId: submission.id })}?tab=submissions`,
            {
              state: {
                scrollToTop: false,
              },
            },
          )
        }
        showBriefTitle={false}
        sizing={{
          md: 6,
          lg: 4,
          xl: 6,
          xxxl: 4,
        }}
      />

      <AutoScrollLoader
        fetchNextPage={submissions.fetchNextPage}
        hasNextPage={submissions.hasNextPage}
        isLoading={submissions.isLoading || submissions.isFetchingNextPage}
        error={submissions.error}
        empty={!submissions.all.length}
      >
        There are no submissions here at the moment.
        <br /> Please come back at a later date.
      </AutoScrollLoader>
    </div>
  );
}
BriefSubmissions.propTypes = {
  setFinalPage: PropTypes.func.isRequired,
};
