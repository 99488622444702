import { useContext } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import RefreshIcon from "@mui/icons-material/Refresh";
import PropTypes from "prop-types";
import { useRouteError } from "react-router-dom";

import ErrorContext from "features/report/context/ErrorContext";

import { Button, HyperLink, Section } from "ui";

const checkBrowserFeatures = () => {
  const features = [
    {
      feature: "Object.hasOwn",
      isSupported: typeof Object.hasOwn !== "undefined",
    },
  ];

  const unsupportedFeatures = features.filter((f) => !f.isSupported);
  return unsupportedFeatures.length > 0
    ? unsupportedFeatures.map((f) => f.feature).join(", ")
    : null;
};

function BrowserSupportError({ unsupportedFeatures }) {
  return (
    <Section className="w-screen h-screen text-center w-1/2 mx-auto">
      <h3 className="text-default-500">Browser Compatibility Issue</h3>
      <p>
        Your current browser doesn&apos;t support the following essential feature(s):{" "}
        <em>{unsupportedFeatures}</em>
      </p>
      <p>Unfortunately, this means you cannot use some parts of the platform as intended.</p>
      <p>
        Please update your browser or try a different one to continue. We recommend using the latest
        version of Chrome, Firefox, Safari, or Edge for the best experience.
      </p>

      <Button
        as={HyperLink}
        href="https://browsehappy.com/"
        isExternal
        color="primary"
        size="lg"
        showAnchorIcon
      >
        Learn How to Update
      </Button>
    </Section>
  );
}
BrowserSupportError.propTypes = {
  unsupportedFeatures: PropTypes.string.isRequired,
};

export default function ErrorBoundary() {
  const error = useRouteError();
  const { showError } = useContext(ErrorContext);
  const unsupportedFeatures = checkBrowserFeatures();
  console.log(error);

  if (unsupportedFeatures) return <BrowserSupportError unsupportedFeatures={unsupportedFeatures} />;

  return (
    <Section className="w-screen h-screen text-center w-1/2 mx-auto">
      <h3 className="text-default-500">Oops! Something went wrong.</h3>

      <p>
        Please try refreshing the page and ensure your browser is up to date. If the issue persists,
        please report the issue to us and we will respond as soon as we can.
      </p>

      <div className="flex flex-wrap gap-4 justify-center">
        <Button
          color="primary"
          onClick={() => window.location.reload()}
          startContent={<RefreshIcon />}
          size="lg"
        >
          Refresh the Page
        </Button>

        <Button
          variant="faded"
          startContent={<ErrorOutlineIcon />}
          size="lg"
          onClick={() => showError({ defaultReason: "issue" })}
        >
          Report an Issue
        </Button>
      </div>

      <small>
        If you&apos;re still experiencing problems, please contact us by emailing{" "}
        <a href="mailto: harrybeard@oditi.com" className="text-success">
          harrybeard@oditi.com
        </a>
      </small>

      <p className="mt-4 pt-4">For the developer: {error.message}</p>
    </Section>
  );
}
