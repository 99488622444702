import { useMemo } from "react";
import PropTypes from "prop-types";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { tv } from "tailwind-variants";

import { briefStatusContent } from "features/brief/data/briefData";
import { briefListPropTypes } from "features/brief/schemas/briefSchema";

import { Button, Card, Chip, Image } from "ui";

import Mixpanel from "adapters/mixpanel";

import routes from "default/routes";

const challengeCard = tv({
  slots: {
    base: "h-full w-full aspect-4/3 after:bg-black/40 after:size-full after:absolute text-primary-foreground",
    title: "font-bold text-xl",
    header: "absolute top-0 z-10 flex justify-between p-4",
    body: "absolute bottom-0 z-10",
    image: "z-0 w-full h-full object-cover",
  },
});

export default function ChallengeCard({
  entry: challenge = {},
  className = "",
  classNames = {},
  trackingLocation = "",
}) {
  const { base, title, header, body, image } = challengeCard();
  const navigate = useNavigate();

  const handleTrackOnClick = () => {
    Mixpanel.trackButton("Card", "view brief", trackingLocation, {
      "Card Type": challenge.type,
    });
    navigate(generatePath(routes.brief, { id: challenge.id }));
  };

  const countdown = useMemo(() => {
    const { reminder } = briefStatusContent(challenge)[challenge.status];
    return reminder;
  }, [challenge]);

  return (
    <Card
      className={twMerge(base(), className, classNames.base)}
      shadow="none"
      isPressable
      onPress={handleTrackOnClick}
      data-testid="challenge-card"
      data-slot="base"
    >
      <Card.CardHeader className={twMerge(header(), classNames.header)} data-slot="header">
        <span className="italic text-md uppercase drop-shadow-lg">Challenge</span>

        {countdown?.length > 0 && (
          <Chip color="danger" radius="sm" className="uppercase old-shake" size="lg">
            {countdown}
          </Chip>
        )}

        {(countdown?.length || 0) <= 0 && ["submitting", "voting"].includes(challenge.status) && (
          <Chip radius="sm" size="lg" color="danger" className="uppercase old-pulse-shadow">
            Live
          </Chip>
        )}
      </Card.CardHeader>

      <Card.CardBody className={twMerge(body(), classNames.body)} data-slot="body">
        <p className={twMerge(title(), classNames.title)} data-slot="title">
          {challenge.title}
        </p>
        <Button
          radius="md"
          className="rounded-2xl bg-content1"
          as={Link}
          to={generatePath(routes.brief, { id: challenge.id })}
        >
          {challenge.entered ? "Resume Progress" : "Get Started"}
        </Button>
      </Card.CardBody>

      <Image
        src={challenge?.thumbnail}
        alt="Challenge card background"
        className={twMerge(image(), classNames.image)}
        removeWrapper
        loading="lazy"
        data-slot="image"
      />
    </Card>
  );
}
ChallengeCard.propTypes = {
  entry: briefListPropTypes.isRequired,
  className: PropTypes.string,
  classNames: PropTypes.shape({
    base: PropTypes.string,
    title: PropTypes.string,
    header: PropTypes.string,
    body: PropTypes.string,
    image: PropTypes.string,
  }),
  trackingLocation: PropTypes.string,
};
