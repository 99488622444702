import { useContext } from "react";
import PropTypes from "prop-types";
import { addMethod, mixed, object } from "yup";

import { ErrorToast } from "features/report";
import { useUpdateUser } from "features/user/api";
import UserContext from "features/user/context/UserContext";

import { ControlledAvatarInput, Form, handleFormError, useForm, yupResolver } from "forms";
import { Button } from "ui";

import { validateFileSize, validateFileTypes } from "utils/validation/validationMethods";

addMethod(mixed, "fileTypes", validateFileTypes);
addMethod(mixed, "fileSize", validateFileSize);

const schema = object().shape({
  icon_onboarding: mixed().required("Icon is a required field").fileTypes().fileSize(26214400),
});

export default function OnboardingAvatar({ onComplete }) {
  const { user } = useContext(UserContext);
  const { mutate, isPending } = useUpdateUser();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <div data-testid="user-onboarding-avatar">
      <h2 className="capitalize mb-5 text-4xl">Add an Avatar</h2>

      <Form
        onSubmit={handleSubmit(({ icon_onboarding: icon }) =>
          icon instanceof File
            ? mutate(
                { icon },
                {
                  onSuccess: onComplete,
                  onError: (error, data) =>
                    handleFormError({
                      error,
                      data,
                      setError,
                      errorPage: "onboarding",
                      toast: ErrorToast,
                    }),
                },
              )
            : onComplete(),
        )}
      >
        <ControlledAvatarInput
          control={control}
          name="icon_onboarding"
          defaultValue={user.icon}
          avatarProps={{
            name: user.username,
          }}
          isInvalid={!!errors.icon_onboarding}
          errorMessage={errors.icon_onboarding?.message}
        />

        <Button
          type="submit"
          color="primary"
          isLoading={isPending}
          trackingName="continue onboarding"
          fullWidth
        >
          Continue
        </Button>
      </Form>
    </div>
  );
}
OnboardingAvatar.propTypes = {
  onComplete: PropTypes.func.isRequired,
};
