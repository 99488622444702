import LaunchIcon from "@mui/icons-material/Launch";
import FacebookIcon from "icons/FacebookIcon";
import InstagramIcon from "icons/InstagramIcon";
import TiktokIcon from "icons/TiktokIcon";
import TwitterIcon from "icons/TwitterIcon";
import YoutubeIcon from "icons/YoutubeIcon";

const socialPrefixes = {
  website: "",
  instagram: "https://instagram.com/",
  facebook: "https://facebook.com/",
  twitter: "https://twitter.com/@",
  youtube: "https://youtube.com/",
  tiktok: "https://tiktok.com/@",
};

const socialIcons = {
  instagram: InstagramIcon,
  facebook: FacebookIcon,
  twitter: TwitterIcon,
  youtube: YoutubeIcon,
  tiktok: TiktokIcon,
  website: LaunchIcon,
};

export { socialPrefixes, socialIcons };
