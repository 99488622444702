import PropTypes from "prop-types";
import { useController } from "react-hook-form";
import { twMerge } from "tailwind-merge";

import NextTextarea from "./Textarea";

export default function Textarea({
  control,
  name,
  defaultValue = "",
  maxLength = 600,
  classNames = {},
  rules = {},
  ...props
}) {
  const {
    field: { value, ...field },
  } = useController({
    control,
    name,
    defaultValue,
    rules,
  });

  return (
    <NextTextarea
      {...field}
      value={value}
      description={maxLength && `${value.length}/${maxLength}`}
      classNames={{
        description: twMerge("text-right font-local", classNames.description),
        ...classNames,
      }}
      {...props}
    />
  );
}
Textarea.propTypes = {
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  default: PropTypes.string,
  maxLength: PropTypes.number,
  defaultValue: PropTypes.string,
  classNames: PropTypes.shape({}),
  rules: PropTypes.shape({}),
};
