import LockIcon from "@mui/icons-material/Lock";
import StarIcon from "@mui/icons-material/Star";
import { useDisclosure } from "@nextui-org/react";

import useSubmissionInsights from "features/submission/hooks/useSubmissionInsights";
import submissionPropTypes from "features/submission/schemas/submissionSchema";

import { Button, Chip, Progress, Shimmer, Tooltip } from "ui";

import BarChart from "./BarChart";
import SubmissionVotesModal from "./SubmissionVotesModal";

const MIN_VOTERS = 10;
const REQUIREDVOTES = 20;

function SubmissionInsightsLoading() {
  return (
    <div className="flex flex-col h-64 width-full gap-4 md:gap-6 p-4 md:p-6 rounded-3xl">
      <Shimmer className="w-full h-2/4 rounded-2xl" />

      <div className="flex justify-between gap-3 w-full h-1/5">
        <Shimmer className="h-full w-1/3 rounded-2xl" />
        <Shimmer className="h-full w-1/3 rounded-2xl" />
        <Shimmer className="h-full w-1/3 rounded-2xl" />
      </div>

      <Shimmer className="w-full h-1/6 rounded-2xl" />
    </div>
  );
}

export default function SubmissionInsights({ submission }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isLoading, genderBreakdown, countryBreakdown, pointPercentile, votePercentile } =
    useSubmissionInsights(submission.id);

  const showBreakdown = submission.votes >= MIN_VOTERS;
  const showPercentile = submission.brief.status === "completed";

  if (isLoading) return <SubmissionInsightsLoading />;

  return (
    <div className="flex flex-col gap-4 md:gap-6 p-4 md:p-6 rounded-3xl bg-content1">
      <div className="flex w-full gap-3 flex-wrap sm:flex-nowrap">
        {!showBreakdown && (
          <div className="flex items-center rounded-xl gap-4 md:gap-6 p-4 md:px-6 bg-content2 w-full">
            <LockIcon />
            <div className="flex flex-col gap-2 grow">
              <Progress value={(submission.votes / MIN_VOTERS) * 100} />
              <div className="uppercase text-sm">
                {MIN_VOTERS - submission.votes} more votes to unlock audience insights!
              </div>
            </div>
          </div>
        )}

        {submission.votes < REQUIREDVOTES && (
          <div className="flex items-center rounded-xl gap-4 md:gap-6 p-4 md:px-6 bg-content2 w-full">
            <StarIcon />
            <div className="flex flex-col gap-2 grow">
              <Progress value={(submission.votes / REQUIREDVOTES) * 100} />
              <div className="uppercase text-sm">
                {REQUIREDVOTES - submission.votes} more votes to be considered for spotlight!
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="flex justify-between gap-3">
        <Tooltip
          placement="top-start"
          showArrow
          content="The number of people who have seen your submission"
        >
          <div className="flex flex-col grow relative shadow text-center rounded-xl p-3 md:p-6 bg-content2">
            <div className="text-xl">{submission.view_count}</div>
            <div className="uppercase text-sm">Impressions</div>
          </div>
        </Tooltip>
        <Tooltip showArrow content="The number of people who have voted for you submission">
          <div className="flex flex-col grow relative shadow text-center rounded-xl p-3 md:p-6 bg-content2">
            {votePercentile && showPercentile && (
              <Chip
                color="secondary"
                variant="shadow"
                radius="sm"
                size="sm"
                className="absolute -top-2 -end-2 uppercase opacity-70"
              >
                {votePercentile}
              </Chip>
            )}
            <div className="text-xl">{submission.vote_count}</div>
            <div className="uppercase text-sm">Votes</div>
          </div>
        </Tooltip>
        <Tooltip
          placement="top-end"
          showArrow
          content="Points give votes from fellow designers more weight."
        >
          <div className="flex flex-col grow relative shadow text-center rounded-xl p-3 md:p-6 bg-content2">
            {pointPercentile && showPercentile && (
              <Chip
                color="secondary"
                variant="shadow"
                radius="sm"
                size="sm"
                className="absolute -top-2 -end-2 uppercase opacity-70"
              >
                {pointPercentile}
              </Chip>
            )}
            <div className="text-xl">{submission.point_count}</div>
            <div className="uppercase text-sm">Points</div>
          </div>
        </Tooltip>
      </div>

      {showBreakdown && genderBreakdown && countryBreakdown && (
        <div className="flex gap-2">
          <div className="bg-content2 rounded-xl text-center p-3 w-full sm:w-1/2">
            <div className="uppercase text-sm pb-2">Voters by Gender</div>
            <div className="h-36">
              <BarChart data={genderBreakdown} />
            </div>
          </div>
          <div className="bg-content2 rounded-xl text-center p-3 w-full sm:w-1/2">
            <div className="uppercase text-sm pb-2">Voters by Country</div>
            <div className="h-36">
              <BarChart data={countryBreakdown} />
            </div>
          </div>
        </div>
      )}

      {submission.votes > 0 && (
        <Button color="primary" className="uppercase" trackingName="voters" onClick={onOpen}>
          View Voters
        </Button>
      )}

      <SubmissionVotesModal submissionId={submission.id} onHide={onClose} show={isOpen} />
    </div>
  );
}
SubmissionInsights.propTypes = {
  submission: submissionPropTypes.isRequired,
};
