import { useMemo } from "react";
import PropTypes from "prop-types";

import { leaderboardEntryPropTypes } from "features/leaderboard";

import { Avatar } from "ui";

import ScrollingRow from "./ScrollingRow";

const comments = ["Voted for you!", "Left you a comment!", "Shared your design!"];

function ScrollingUsers({ users, ...rowProps }) {
  // We use useMemo since we dont want rand to be called on every pause, leading to different comments
  const usersWithText = useMemo(
    () => (
      <>
        {users.map(({ user }) => (
          <div className="bg-content1 py-6 min-w-44 flex flex-col items-center rounded-2xl text-center gap-4">
            <Avatar size="lg" src={user.icon} name={user.username} />
            <span className="font-roman text-sm">{comments[Math.floor(Math.random() * 3)]}</span>
          </div>
        ))}
      </>
    ),
    [],
  );

  return <ScrollingRow {...rowProps}>{usersWithText}</ScrollingRow>;
}
ScrollingUsers.propTypes = {
  users: PropTypes.arrayOf(leaderboardEntryPropTypes).isRequired,
};

export default function LandingUsers({ users, isPaused }) {
  return (
    <>
      <div className="z-20 text-center mb-48 flex flex-col gap-4 px-4">
        <h2 className="max-w-[900px]">Get recognition from fellow designers</h2>
        <p>Share your work, receive feedback, and support others by voting for your favourites.</p>
      </div>

      <div className="absolute bottom-1/4 pointer-events-none">
        <div className="flex gap-4">
          <ScrollingUsers users={users} isPaused={isPaused} duration={30} />
          <ScrollingUsers users={users} isPaused={isPaused} duration={30} />
          <ScrollingUsers users={users} isPaused={isPaused} duration={30} />
        </div>
      </div>
    </>
  );
}
LandingUsers.propTypes = {
  users: PropTypes.arrayOf(leaderboardEntryPropTypes).isRequired,
  isPaused: PropTypes.bool.isRequired,
};
