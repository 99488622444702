import PropTypes from "prop-types";
import { useController } from "react-hook-form";
import { twMerge } from "tailwind-merge";

import api from "adapters/api";

import Input from "./Input";

export default function ControlledUsernameInput({
  name,
  control,
  defaultValue = "",
  maxLength = 15,
  classNames = {},
  isInvalid = false,
  errorMessage = null,
  ...inputProps
}) {
  const {
    field: { value, ...field },
    formState: { errors },
  } = useController({
    name,
    control,
    defaultValue,
    rules: {
      validate: {
        minLength: (v) => v.length > 0 || "Username must have at least 1 character",
        maxLength: (v) =>
          v.length <= maxLength || `Username cannot be longer than ${maxLength} characters`,
        pattern: (v) =>
          /^\w+$/.test(v) || "Username can only contain letters, numbers and underscores",
        checkUsername: async (v) => {
          if (v === defaultValue) return true;

          const res = await api.get({
            url: "users/available",
            params: { username: v },
          });

          if (res.success && !res.data.available) return "This username has already been taken";
          return true;
        },
      },
    },
  });

  return (
    <Input
      {...field}
      value={value}
      description={maxLength && `${value.length}/${maxLength}`}
      maxLength={maxLength}
      classNames={{
        description: twMerge("text-right", classNames.description),
        ...classNames,
      }}
      isInvalid={!!errors[name] || isInvalid}
      errorMessage={errors[name]?.message || errorMessage}
      {...inputProps}
    />
  );
}
ControlledUsernameInput.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.shape({}).isRequired,
  defaultValue: PropTypes.string,
  maxLength: PropTypes.number,
  classNames: PropTypes.shape({}),
  isInvalid: PropTypes.bool,
  errorMessage: PropTypes.string,
};
