import { CallToAction } from "ui";

import routes from "default/routes";

export default function SubmissionVoteBlock(props) {
  return (
    <CallToAction
      title={
        <>
          Exercise Your
          <br className="hidden lg:block" /> Right to Vote
        </>
      }
      description="You do not have to enter a brief in order to vote. Visit anytime and take a look at the incredible ideas our community has created. Submissions with more votes are more likely to win, so support your favourite entries now!"
      cta="Vote Now"
      to={routes.voting}
      trackingName="view voting"
      {...props}
    />
  );
}
