import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

export default function Row({ className = "", gap = 3, children, ...props }) {
  return (
    <div className={twMerge(`flex flex-wrap -mx-${gap} [&>*]:px-${gap}`, className)} {...props}>
      {children}
    </div>
  );
}
Row.propTypes = {
  className: PropTypes.string,
  gap: PropTypes.number,
  children: PropTypes.node.isRequired,
};
