import { forwardRef, useRef, useState } from "react";
import BackupIcon from "@mui/icons-material/Backup";
import ClearIcon from "@mui/icons-material/Clear";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import { twMerge } from "tailwind-merge";

import Input from "forms/Input";
import { Button, Progress } from "ui";

import { formatBytes, writeFilesToInput } from "utils/helpers";

const allowedImages = ["image/png", "image/jpg", "image/jpeg", "image/webp"];
const typeToExt = (type) => `.${type.split("/")[1]}`;

function AcceptedFileTypes({ fileTypes, maxSize }) {
  if (fileTypes.length <= 0 && !maxSize) return null;

  return (
    <>
      {" "}
      We can accept only {fileTypes.map((ft) => `.${ft.split("/")[1]}`).join(", ")} files
      {maxSize && <> that are less than {formatBytes(maxSize)} in size.</>}
    </>
  );
}
AcceptedFileTypes.propTypes = {
  fileTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  maxSize: PropTypes.number.isRequired,
};

function FormFileDropzone({
  acceptedFileTypes,
  isDragReject,
  isInvalid,
  handleOpenFileDialog,
  className = "",
}) {
  return (
    <div
      className={twMerge(
        "flex flex-col items-center text-center p-6 border border-dashed border-2 border-foreground transition-colors duration-300 ease-in-out relative",
        className,
        (isDragReject || isInvalid) && "bg-danger-100 !text-danger border-danger",
      )}
      data-role="form-error-component"
    >
      {!isDragReject ? (
        <BackupIcon className="!size-16 mr-2 mb-1" />
      ) : (
        <ClearIcon className="!size-16 mr-2 mb-1" />
      )}

      <p
        className={twMerge(
          "w-3/4 md:w-1/2 text-primary-300",
          (isDragReject || isInvalid) && "!text-danger-400",
        )}
      >
        Drag &#39;n&#39; drop files here to upload.
        {acceptedFileTypes()}
      </p>

      <Button
        color="primary"
        className="absolute bottom-0 -mb-4 md:w-1/4 px-8 hover:!opacity-100 uppercase"
        variant="shadow"
        onClick={handleOpenFileDialog}
        trackingName="browse file upload"
      >
        Select Files
      </Button>
    </div>
  );
}
FormFileDropzone.propTypes = {
  acceptedFileTypes: PropTypes.func.isRequired,
  isDragReject: PropTypes.bool.isRequired,
  isInvalid: PropTypes.bool.isRequired,
  handleOpenFileDialog: PropTypes.func.isRequired,
  className: PropTypes.string,
};

function DefaultAs({ displayName, handleOpenFileDialog }) {
  return (
    <div className="flex justify-between items-center">
      {displayName}

      <Button color="primary" onClick={handleOpenFileDialog} trackingName="browse file upload">
        Select Files
      </Button>
    </div>
  );
}
DefaultAs.propTypes = {
  displayName: PropTypes.string.isRequired,
  handleOpenFileDialog: PropTypes.func.isRequired,
};

const FormFile = forwardRef(
  (
    {
      name,
      onUpdate,
      onCancel,
      as,
      maxSize,
      multiple,
      className,
      labelClassName,
      isInvalid,
      placeholder,
      fileTypes,
      dropzone,
      fileProps,
      ...props
    },
    ref,
  ) => {
    const [displayName, setDisplayName] = useState(placeholder);
    const [progress, setProgress] = useState(0);
    const fileControlRef = useRef(null);
    const As = as;

    // If a ref is not provided, we create one so we can upload the file from the dropzone.
    ref = ref || useRef(null);

    const handleFileUpload = async (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        toast.error(<div data-testid="error-toast">{rejectedFiles[0].errors[0].message}</div>, {
          autoClose: 3000,
        });
        return;
      }

      if (acceptedFiles.length <= 0 || (progress > 0 && progress < 100)) return;

      if (!!multiple && acceptedFiles.length > multiple) {
        toast.error(
          <div data-testid="error-toast">You can only upload a maximum of {multiple} files.</div>,
          {
            autoClose: 3000,
          },
        );
        return;
      }

      writeFilesToInput(acceptedFiles, ref.current);

      let err = "";
      if (onUpdate)
        err = (await onUpdate(multiple ? acceptedFiles : acceptedFiles[0], setProgress)) || "";

      if (!err) {
        setDisplayName(acceptedFiles.map((f) => f.name).join(", "));
        return;
      }

      toast.error(<div data-testid="error-toast">{err}</div>, {
        autoClose: 3000,
      });
    };

    const handleOpenFileDialog = () => fileControlRef.current.click();

    const handleFileValidation = (file) => {
      if (fileTypes.length > 0 && !fileTypes.includes(file.type)) {
        return {
          code: "wrong-file-type",
          message: `File must be one of ${fileTypes.map((ft) => `.${ft.split("/")[1]}`).join(", ")}.`,
        };
      }

      if (maxSize && file.size > maxSize) {
        return {
          code: "file-too-large",
          message: `File is larger than ${formatBytes(maxSize)}.`,
        };
      }

      return null;
    };

    return (
      <Dropzone onDrop={handleFileUpload} noDrag={!dropzone} validator={handleFileValidation}>
        {({ getRootProps, getInputProps, isDragReject, isDragAccept }) => (
          <div {...getRootProps()} onClick={(e) => e.stopPropagation()} role="button" tabIndex={0}>
            {progress <= 0 || progress >= 100 ? (
              // eslint-disable-next-line jsx-a11y/label-has-associated-control
              <label
                className={twMerge("w-full mb-0", labelClassName)}
                htmlFor={name}
                ref={fileControlRef}
              >
                <As
                  handleOpenFileDialog={handleOpenFileDialog}
                  displayName={displayName}
                  maxSize={maxSize}
                  fileTypes={fileTypes}
                  isDragReject={isDragReject}
                  isDragAccept={isDragAccept}
                  isInvalid={isInvalid}
                  className={className}
                  acceptedFileTypes={() => AcceptedFileTypes({ fileTypes, maxSize })}
                  {...fileProps}
                />
              </label>
            ) : (
              <div className={twMerge("p-4 flex justify-between items-center gap-6", className)}>
                <Progress value={progress} />

                {onCancel && (
                  <Button
                    color="danger"
                    variant="flat"
                    onClick={onCancel}
                    trackingName="cancel file upload"
                    size="sm"
                  >
                    Cancel
                  </Button>
                )}
              </div>
            )}

            <Input
              {...getInputProps()}
              classNames={{
                base: "!mt-0",
                inputWrapper: "hidden",
              }}
              accept={fileTypes.map(typeToExt).toString()}
              multiple={!!multiple}
              ref={ref}
              onClick={() => {
                ref.current.value = "";
              }} // allows us to upload the same file multiple times
              id={name}
              name={name}
              isInvalid={isInvalid}
              {...props}
            />
          </div>
        )}
      </Dropzone>
    );
  },
);
FormFile.propTypes = {
  name: PropTypes.string.isRequired,
  onUpdate: PropTypes.func,
  onCancel: PropTypes.func,
  as: PropTypes.elementType,
  maxSize: PropTypes.number,
  multiple: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  isInvalid: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  fileTypes: PropTypes.arrayOf(PropTypes.string),
  dropzone: PropTypes.bool,
  fileProps: PropTypes.shape({}),
};
FormFile.defaultProps = {
  onUpdate: undefined,
  onCancel: undefined,
  as: DefaultAs,
  maxSize: undefined,
  multiple: false,
  isInvalid: false,
  className: "",
  labelClassName: "",
  placeholder: "Choose File",
  fileTypes: allowedImages,
  dropzone: false,
  fileProps: {},
};

export default FormFile;

FormFile.Dropzone = forwardRef((props, ref) => (
  <FormFile as={FormFileDropzone} ref={ref} dropzone {...props} />
));
