import { useCallback } from "react";
import { css } from "aphrodite";
import PropTypes from "prop-types";

import animationStyles from "ui/animations";
import Container from "ui/layout/Container";

import useKeyGen from "hooks/useKeyGen";

export default function TitleBanner({
  titleComponents = [],
  subText = "",
  titleClassName = "",
  backgroundImage = "",
  backgroundVideo = "",
}) {
  const keyGen = useKeyGen();

  const playVideo = useCallback((node) => {
    if (!node) return;

    node.setAttribute("autoplay", "autoplay");
  }, []);

  return (
    <div className="py-12 text-center flex flex-col items-center justify-center h-screen">
      {backgroundImage && (
        <img
          className="absolute w-full h-full object-cover"
          src={backgroundImage}
          alt="banner background"
        />
      )}
      {backgroundVideo && (
        <video
          playsInline
          preload="auto"
          muted
          loop
          className="w-full h-full object-cover absolute"
          ref={playVideo}
        >
          <source src={backgroundVideo} type="video/mp4" />
        </video>
      )}

      <Container className="">
        <h1 className={`mb-14 uppercase inline-block ${titleClassName}`}>
          <span>
            {titleComponents.map((title) => (
              <div key={keyGen.getKey(title)}>
                <div
                  className={`inline-block w-full old-transition-underline-center-load text-nowrap ${css(animationStyles.fadeInUp)}`}
                >
                  {title}
                </div>
                <br />
              </div>
            ))}
          </span>
        </h1>

        {subText !== "" && (
          <p className="text-xl lg:max-w-[75%] xl:max-w-[50%] m-auto">{subText}</p>
        )}
      </Container>
    </div>
  );
}
TitleBanner.propTypes = {
  titleComponents: PropTypes.arrayOf(PropTypes.string),
  subText: PropTypes.node,
  titleClassName: PropTypes.string,
  backgroundImage: PropTypes.string,
  backgroundVideo: PropTypes.string,
};
