import { useMemo, useState } from "react";
import { extendVariants, Avatar as NextAvatar } from "@nextui-org/react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";
import { tv } from "tailwind-variants";

const CustomAvatar = extendVariants(NextAvatar, {
  variants: {
    size: {
      xl: {
        base: "w-16 h-16 text-large",
      },
    },
  },
});

const defaultColors = [
  "bg-[#FE2401] ring-[#FE2401]", // Lava Orange
  "bg-[#22372B] ring-[#22372B]", // Deep Forest
  "bg-[#70000E] ring-[#70000E]", // Dark Ruby
  "bg-[#0953BC] ring-[#0953BC]", // Bautical Blue
  "bg-[#770009] ring-[#770009]", // Crimson Red
  "bg-[#586D51] ring-[#586D51]", // Olive Forest
  "bg-[#001960] ring-[#001960]", // Electric Blue
  "bg-[#C3B79D] ring-[#C3B79D]", // Creme White
  "bg-[#001D51] ring-[#001D51]", // Imperial Blue
  "bg-[#9B0047] ring-[#9B0047]", // Fuchsia Flame
  "bg-[#7AAABC] ring-[#7AAABC]", // Calm Water
  "bg-[#F20819] ring-[#F20819]", // Volcano Flame
  "bg-[#94BBCF] ring-[#94BBCF]", // Glacier Blue
  "bg-[#B7830D] ring-[#B7830D]", // Golden Aura
  "bg-[#EDAB18] ring-[#EDAB18]", // Honeyed Gold
  "bg-[#363D3F] ring-[#363D3F]", // Deep Space
  "bg-[#1D3849] ring-[#1D3849]", // Cosmic Blue
  "bg-[#720013] ring-[#720013]", // Dark Ruby
  "bg-[#454E57] ring-[#454E57]", // Oceanic Onyx
  "bg-[#416D5A] ring-[#416D5A]", // Moss Shade
  "bg-[#184A4C] ring-[#184A4C]", // Ocean Depths
  "bg-[#7F1354] ring-[#7F1354]", // Berry Velvet
  "bg-[#55AD73] ring-[#55AD73]", // Mint Mirage
  "bg-[#978EAE] ring-[#978EAE]", // Soft Lilac
  "bg-[#B23B32] ring-[#B23B32]", // Copper Red
];

function hashStr(str) {
  let hash = 0;
  let i;
  let chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i += 1) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash >>> 0; // Ensure the hash is a non-negative integer
}

const avatar = tv({
  slots: {
    ring: "ring-white",
    flag: "absolute top-0 right-0 bg-white text-black rounded-full !flex items-center justify-center shadow",
  },
  variants: {
    size: {
      sm: {
        ring: "ring-0",
        flag: "!size-6 !text-sm -mr-3 -mt-3",
      },
      md: {
        ring: "ring-2",
        flag: "!size-7 !text-base -mr-3 -mt-3",
      },
      lg: {
        ring: "ring-2",
        flag: "!size-8 !text-lg -mr-2 -mt-2",
      },
      xl: {
        ring: "ring-2",
        flag: "!size-9 !text-lg -mr-2 -mt-2",
      },
    },
  },
});

export default function Avatar({
  country = "",
  name = "",
  src = "",
  className = "",
  size = "md",
  ...props
}) {
  const [loaded, setLoaded] = useState(false);
  const { flag, ring } = avatar({ size });

  const defaultColor = useMemo(() => {
    if (src && loaded) return "";
    const colorIndex = BigInt(`0x${hashStr(name)}`) % BigInt(defaultColors.length);
    return defaultColors[Number(colorIndex)];
  }, [src, loaded]);

  return (
    <div className="relative inline-block">
      <CustomAvatar
        src={src}
        size={size}
        className={twMerge(defaultColor, src && loaded && ring(), className)}
        imgProps={{
          onLoad: () => setLoaded(true),
        }}
        fallback={<div />}
        {...props}
      />

      {country && (
        <div className={flag()}>
          <img
            src={`https://flagcdn.com/20x15/${country.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/40x30/${country.toLowerCase()}.png 2x,
    https://flagcdn.com/60x45/${country.toLowerCase()}.png 3x`}
            alt={country}
          />
        </div>
      )}
    </div>
  );
}
Avatar.propTypes = {
  country: PropTypes.string,
  src: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
};
