import PropTypes from "prop-types";

import { publicUserPropTypes } from "features/user";

const commentTypes = {
  id: PropTypes.number.isRequired,
  content: PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired,
  rated: PropTypes.bool.isRequired,
  user: publicUserPropTypes.isRequired,
};

const feedbackPropTypes = PropTypes.shape({
  ...commentTypes,
  parent: PropTypes.shape(commentTypes),
});

export default feedbackPropTypes;
