// Config shared between all apps

// Routes
import { ErrorBoundary, NotFound } from "features/report";
import { SignInModal } from "features/user";

import routes from "./routes";

export const browserRoutes = {
  notFound: {
    path: routes.notFound,
    element: <NotFound />,
  },
  auth: {
    path: routes.auth,
    element: <SignInModal show />,
  },
};

export const createRoutes = (r) => {
  const finalRoutes = [];

  Object.values(r).forEach((route) => {
    finalRoutes.push({
      ...route,
      ...(route.children && { children: createRoutes(route.children) }),
      errorElement: route.errorElement || <ErrorBoundary />,
    });
  });

  return finalRoutes;
};
