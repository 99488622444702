import { useContext, useRef, useState } from "react";
import PropTypes from "prop-types";

import { UserContext, userPropTypes } from "features/user";

import EngagementCTA from "./EngagementCTA";
import FeedbackInput from "./FeedbackInput";
import FeedbackList from "./FeedbackList";

export default function FeedbackContainer({ submissionId, designer }) {
  const [parent, setParent] = useState(null);
  const inputRef = useRef(null);
  const { user } = useContext(UserContext);

  const handleReply = (comment) => {
    setParent(comment);
    if (inputRef.current) inputRef.current.focus();
  };

  return (
    <div className="bg-white rounded-3xl flex flex-col max-h-[85vh] min-h-[300px]">
      <div className="flex-1 overflow-y-auto px-6 pt-4">
        {user.doesSessionExist && <FeedbackList {...{ submissionId, designer, handleReply }} />}
        <EngagementCTA designer={designer} user={user} />
      </div>
      <div className="px-6 pb-6 border-t-6 border-gray-200 relative">
        <div className="absolute inset-x-0 top-[-38px] h-10 bg-gradient-to-t from-white via-white to-transparent pointer-events-none w-full" />
        <FeedbackInput
          ref={inputRef}
          submissionId={submissionId}
          parent={parent}
          onCancelReply={() => setParent(null)}
        />
      </div>
    </div>
  );
}
FeedbackContainer.propTypes = {
  submissionId: PropTypes.number.isRequired,
  designer: userPropTypes.isRequired,
};
