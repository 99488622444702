import PropTypes from "prop-types";
import { SessionAuth } from "supertokens-auth-react/recipe/session";

export default function ProtectedRoute({ component, ...args }) {
  const Component = component;

  return (
    <SessionAuth>
      <Component {...args} />
    </SessionAuth>
  );
}

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
};
