import { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

const scrollingAnimations = {
  forward: {
    from: { transform: "translate(0)" },
    to: { transform: "translate(100%)" },
  },
  backward: {
    from: { transform: "translate(0)" },
    to: { transform: "translate(-100%)" },
  },
  upward: {
    from: { transform: "translate(0, 0)" },
    to: { transform: "translate(0, -100%)" },
  },
  downward: {
    from: { transform: "translate(0, 0)" },
    to: { transform: "translate(0, 100%)" },
  },
};

export default function ScrollingRow({
  isPaused = false,
  duration = 20,
  direction = "forward",
  className = "",
  motionProps = {},
  children,
}) {
  const controls = useAnimation();
  const animation = scrollingAnimations[direction];

  useEffect(() => {
    if (!isPaused) {
      controls.start({
        initial: {
          ...animation.from,
          ...motionProps.initial,
        },
        ...animation.to,
        ...motionProps.animate,
        transition: {
          duration,
          repeat: Infinity,
          ease: "linear",
          ...motionProps.transition,
        },
      });
    } else {
      controls.stop();
    }
  }, [isPaused]);

  return (
    <motion.div className={twMerge("flex gap-4 relative", className)} animate={controls}>
      {children}
    </motion.div>
  );
}
ScrollingRow.propTypes = {
  isPaused: PropTypes.bool,
  duration: PropTypes.number,
  direction: PropTypes.oneOf(["forward", "backward", "upward", "downward"]),
  className: PropTypes.string,
  motionProps: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
};
