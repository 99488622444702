import { useContext, useEffect, useReducer, useState } from "react";
import structuredClone from "@ungap/structured-clone";
import PropTypes from "prop-types";
import { generatePath, Link } from "react-router-dom";
import { toast } from "react-toastify";

import { useProtectedAction } from "features/authentication";
import { organisationPropTypes } from "features/organisation";
import { ErrorToast } from "features/report";
import { publicUserPropTypes, User, UserContext, userListPropTypes } from "features/user";

import { Button, Modal, Tab, Tabs } from "ui";

import useKeyGen from "hooks/useKeyGen";

import api from "adapters/api";

import routes from "default/routes";

const showErrorToast = (res) => {
  toast(
    <ErrorToast
      errorMessage={res.errorDetails.message}
      errorProps={{
        defaultReason: "issue",
        defaultPage: "other",
        apiError: res.errorDetails,
      }}
    />,
    {
      limit: 1,
    },
  );
};

function followDataReducer(state, action) {
  if (action.type === "FOLLOW_FOLLOWER") {
    const nowFollowing = structuredClone(state.followers);
    const index = nowFollowing.findIndex((f) => f.id === action.id);

    // We cannot follow a follower that doesnt exist
    if (index === -1) return { ...state };

    nowFollowing[index].following = action.follow;
    return { ...state, followers: nowFollowing };
  }
  if (action.type === "ADD_FOLLOWING") {
    return {
      ...state,
      following: {
        ...state.following,
        [action.urlPrefix]: [...state.following[action.urlPrefix], action.following],
      },
    };
  }
  if (action.type === "REMOVE_FOLLOWING") {
    return {
      ...state,
      following: {
        ...state.following,
        [action.urlPrefix]: state.following[action.urlPrefix].filter((f) => f.id !== action.id),
      },
    };
  }

  // Default
  return { ...state, ...action };
}

function UnfollowAction({ user, onClick }) {
  const { user: currentUser } = useContext(UserContext);

  if (currentUser.id !== user.id) return null;

  return (
    <Button color="primary" onClick={onClick} trackingName="unfollow" trackingLocation="modal">
      Unfollow
    </Button>
  );
}
UnfollowAction.propTypes = {
  user: publicUserPropTypes.isRequired,
  onClick: PropTypes.func.isRequired,
};

function Followers({ user, followers, dispatch, setFollowingCount }) {
  const { user: currentUser } = useContext(UserContext);

  const [handleFollow] = useProtectedAction(async (nowFollowing) => {
    const res = await api.put({ url: `users/${nowFollowing.id}/follow` });

    if (!res.success) {
      showErrorToast(res);
      return;
    }

    dispatch({ type: "FOLLOW_FOLLOWER", id: nowFollowing.id, follow: true });
    dispatch({
      type: "ADD_FOLLOWING",
      urlPrefix: "users",
      following: nowFollowing,
    });

    // update the count outside the modal
    setFollowingCount((prev) => prev + 1);
  });

  // We only show follow back option for logged in users, on their profile, for users who follow them but they do not follow
  const showActionButton = (followingUser) =>
    currentUser.doesSessionExist && currentUser.id === user.id && !followingUser.following;

  return (
    <div className="mt-4" data-testid="followers-modal-followers">
      {followers.map((followingUser) => (
        <Link
          to={generatePath(routes.userProfile, {
            username: followingUser.username,
          })}
          className="w-full flex items-center justify-between"
          key={followingUser.id}
        >
          <User
            avatarProps={{
              src: followingUser.icon,
            }}
            badges={followingUser.badges}
            name={followingUser.username}
            description="People"
          />

          {showActionButton(followingUser) && (
            <Button
              color="success"
              onClick={(e) => {
                e.preventDefault();
                handleFollow(followingUser);
              }}
              trackingName="follow"
              trackingLocation="modal"
            >
              Follow Back
            </Button>
          )}
        </Link>
      ))}
    </div>
  );
}
Followers.propTypes = {
  user: PropTypes.oneOfType([publicUserPropTypes, organisationPropTypes]).isRequired,
  followers: PropTypes.arrayOf(userListPropTypes).isRequired,
  dispatch: PropTypes.func.isRequired,
  setFollowingCount: PropTypes.func.isRequired,
};

function Following({ user, following, dispatch, setFollowingCount }) {
  const keyGen = useKeyGen();

  const [handleUnfollow] = useProtectedAction(async (urlPrefix, id) => {
    const res = await api.delete({ url: `${urlPrefix}/${id}/follow` });

    if (!res.success) {
      showErrorToast(res);
      return;
    }

    dispatch({ type: "REMOVE_FOLLOWING", urlPrefix, id });
    dispatch({ type: "FOLLOW_FOLLOWER", follow: false, id });

    // Update the count outside the modal
    setFollowingCount((prev) => prev - 1);
  });

  return (
    <div className="mt-4" data-testid="followers-modal-following">
      {following.users.map((followedUser) => (
        <Link
          key={keyGen.getKey(followedUser)}
          to={generatePath(routes.userProfile, {
            username: followedUser.username,
          })}
          className="w-full flex items-center justify-between"
        >
          <User
            avatarProps={{
              src: followedUser.icon,
            }}
            badges={followedUser.badges}
            name={followedUser.username}
            description="People"
          />

          <UnfollowAction
            user={user}
            onClick={(e) => {
              e.preventDefault();
              handleUnfollow("users", followedUser.id);
            }}
          />
        </Link>
      ))}

      {following.organisations.map((followedOrganisation) => (
        <Link
          key={keyGen.getKey(followedOrganisation)}
          to={generatePath(routes.organisation, {
            id: followedOrganisation.id,
          })}
          className="w-full flex items-center justify-between"
        >
          <User
            avatarProps={{
              src: followedOrganisation.icon,
            }}
            name={followedOrganisation.name}
            description="Company"
          />

          <UnfollowAction
            user={user}
            onClick={(e) => {
              e.preventDefault();
              handleUnfollow("organisations", followedOrganisation.id);
            }}
          />
        </Link>
      ))}
    </div>
  );
}
Following.propTypes = {
  user: PropTypes.oneOfType([publicUserPropTypes, organisationPropTypes]).isRequired,
  following: PropTypes.shape({
    users: PropTypes.arrayOf(userListPropTypes),
    organisations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        icon: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  setFollowingCount: PropTypes.func.isRequired,
};

export default function FollowersModal({
  onHide,
  user,
  followersCount,
  setFollowingCount = undefined,
  followingCount = 0,
  show = false,
  urlPrefix = "users",
  defaultTab = "followers",
}) {
  const [loading, setLoading] = useState(true);
  const [followData, dispatch] = useReducer(followDataReducer, {
    followers: [],
    following: { users: [], organisations: [] },
  });

  useEffect(() => {
    if (!show) return;

    const getFollowData = async () => {
      const [followers, following] = await Promise.all([
        api.get({ url: `${urlPrefix}/${user.id}/followers` }),
        ...(urlPrefix === "users" ? [api.get({ url: `users/${user.id}/following` })] : []),
      ]);

      if (!followers.success || !(following?.success || true)) return;

      dispatch({
        followers: followers.data,
        ...(urlPrefix === "users" && {
          following: {
            users: following.data.users_followed,
            organisations: following.data.organisations_followed,
          },
        }),
      });
      setLoading(false);
    };
    getFollowData();
  }, [show]);

  return (
    <Modal
      isOpen={show}
      onOpenChange={onHide}
      data-testid="followers-modal"
      scrollBehavior="outside"
      classNames={{
        base: "overflow-y-visible p-6",
        closeButton: "-mt-10 bg-content1 shadow-2xl",
      }}
    >
      <Tabs color="primary" radius="full" size="md" fullWidth defaultSelectedKey={defaultTab}>
        <Tab key="followers" title={`${followersCount} Followers`}>
          {!loading ? (
            <Followers
              user={user}
              followers={followData.followers}
              dispatch={dispatch}
              setFollowingCount={setFollowingCount}
              loading={loading}
            />
          ) : (
            <div className="mt-4">
              <User.Loading />
              <User.Loading />
            </div>
          )}
        </Tab>

        {urlPrefix === "users" && (
          <Tab key="following" title={`${followingCount} Following`}>
            {!loading ? (
              <Following
                user={user}
                following={followData.following}
                dispatch={dispatch}
                setFollowingCount={setFollowingCount}
                loading={loading}
              />
            ) : (
              <div className="mt-4">
                <User.Loading />
                <User.Loading />
              </div>
            )}
          </Tab>
        )}
      </Tabs>
    </Modal>
  );
}
FollowersModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  user: PropTypes.oneOfType([publicUserPropTypes, organisationPropTypes]).isRequired,
  followersCount: PropTypes.number.isRequired,
  followingCount: PropTypes.number,
  setFollowingCount: PropTypes.func,
  show: PropTypes.bool,
  urlPrefix: PropTypes.string,
  defaultTab: PropTypes.oneOf(["followers", "following"]),
};
