import { Children, cloneElement, isValidElement, useState } from "react";
import { Tooltip as NextTooltip } from "@nextui-org/react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

export default function Tooltip({ className = "", children, ...props }) {
  const [isOpen, setIsOpen] = useState(false);

  // We have to manually control the tooltip to allow it to work on mobile.
  // We add props to the child using cloneElement (not recommended) to prevent having to pass the props
  // to the child on each declaration.
  const controlledChildren = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child, {
        onMouseEnter: () => setIsOpen(true),
        onMouseLeave: () => setIsOpen(false),
        onPress: () => setIsOpen(!isOpen),
      });
    }
    return child;
  });

  return (
    <NextTooltip className={twMerge("font-roman text-xs", className)} isOpen={isOpen} {...props}>
      {controlledChildren[0]}
    </NextTooltip>
  );
}
Tooltip.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
