import { Children, cloneElement, forwardRef, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";

import useScrollTabs from "hooks/useScrollTabs";

import Container from "./Container";

function ScrollSections({ children, ...props }) {
  const elementsRef = useRef([]);
  const tabs = useMemo(
    () =>
      Children.toArray(children).filter(
        (child) =>
          child.type?.displayName === "ScrollSection" ||
          child.type?.displayName === "ScrollSectionFluid",
      ),
    [children],
  );
  const [elements, setElements] = useState([]);
  const fixTabs = useScrollTabs(elements, [elements]);

  useEffect(() => {
    elementsRef.current = elementsRef.current.slice(0, tabs.length);
    setElements(elementsRef.current.map((element) => ({ current: element })));
  }, [tabs]);

  return (
    <div id="tabs" {...props}>
      {Children.toArray(children).map((child) => {
        if (
          child.type?.displayName !== "ScrollSection" &&
          child.type?.displayName !== "ScrollSectionFluid"
        )
          return child;

        const tabWithRef = cloneElement(child, {
          ref: (node) => elementsRef.current.push(node),
          fixed: fixTabs,
        });
        return tabWithRef;
      })}
    </div>
  );
}

ScrollSections.propTypes = {
  children: PropTypes.node.isRequired,
};

const ScrollSection = forwardRef(({ children, first, last, fixed, className }, ref) => (
  <>
    <div
      className={`w-full ${fixed && last ? "min-h-screen" : ""} ${fixed && !last ? "min-h-[150vh]" : ""} ${!first ? "-mt-12" : ""} after:pb-12 after:block ${className}`}
    >
      <Container
        className="min-h-screen pt-12 md:pt-24 pb-24 flex flex-col justify-center overflow-hidden"
        ref={ref}
      >
        {children}
      </Container>
    </div>
    <div className={last && fixed ? "min-h-[50vh]" : "hidden"} />
  </>
));

ScrollSection.propTypes = {
  children: PropTypes.node.isRequired,
  first: PropTypes.bool,
  last: PropTypes.bool,
  fixed: PropTypes.bool,
  className: PropTypes.string,
};

ScrollSection.defaultProps = {
  first: false,
  last: false,
  fixed: false,
  className: "",
};

const ScrollSectionFluid = forwardRef(({ children, className }, ref) => (
  <div className={`w-full ${className}`}>
    <div ref={ref}>{children}</div>
  </div>
));

ScrollSectionFluid.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

ScrollSectionFluid.defaultProps = {
  className: "",
};

ScrollSection.displayName = "ScrollSection";
ScrollSections.ScrollSection = ScrollSection;

ScrollSectionFluid.displayName = "ScrollSectionFluid";
ScrollSections.ScrollSectionFluid = ScrollSectionFluid;

export default ScrollSections;
