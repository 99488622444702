import PropTypes from "prop-types";
import { toast } from "react-toastify";

function DefaultToast({ errorMessage }) {
  return <div>{errorMessage}</div>;
}
DefaultToast.propTypes = {
  errorMessage: PropTypes.string.isRequired,
};

const handleFormError = ({
  error,
  data,
  setError,
  errorPage = "",
  toast: errorToast = DefaultToast,
} = {}) => {
  if (!error || !data) return;
  const apiErrors = Object.entries(error.parseError(data));
  const ErrorToast = errorToast;

  if (setError && apiErrors.length) {
    apiErrors.forEach(([name, message]) => {
      setError(name, { message, type: "custom" });
    });
  } else {
    toast(
      <ErrorToast
        errorMessage={error.details.message}
        errorProps={{
          defaultReason: "issue",
          defaultPage: errorPage,
          apiError: error.details,
        }}
      />,
      { limit: 1 },
    );
  }
};

export { handleFormError };
