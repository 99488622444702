import PropTypes from "prop-types";

import { userListPropTypes } from "features/user";

const leaderboardEntryPropTypes = PropTypes.shape({
  user: userListPropTypes.isRequired,
  score: PropTypes.number.isRequired,
  position: PropTypes.number.isRequired,
});

export { leaderboardEntryPropTypes };
