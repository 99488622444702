import { useState } from "react";
import PropTypes from "prop-types";
import { scroller } from "react-scroll";
import { toast } from "react-toastify";
import { ObjectSchema, ValidationError } from "yup";

import { validationErrorToObject } from "adapters/api";

function DefaultToast({ errorMessage }) {
  return <div>{errorMessage}</div>;
}
DefaultToast.propTypes = {
  errorMessage: PropTypes.string.isRequired,
};

const scrollToFirstError = (elements, errors) => {
  if (!errors) return;

  const validElements = Object.keys(errors).filter(
    (key) => elements.querySelector(`[name="${key}"]`) !== null,
  );

  // We cant find any elements to scroll to
  if (!validElements.length) return;

  const firstErrorKey = validElements.reduce((a, b) => {
    const aHeight = elements.querySelector(`[name="${a}"]`).getBoundingClientRect().top;
    const bHeight = elements.querySelector(`[name="${b}"]`).getBoundingClientRect().top;
    return aHeight < bHeight ? a : b;
  });

  scroller.scrollTo(firstErrorKey, {
    delay: 0,
    duration: 100,
    offset: -100,
  });
};

function ValidateForm({
  validationSchema,
  onSubmit,
  errorPage = "",
  toast: errorToast = DefaultToast,
  parseData = undefined,
  scrollOnSubmit = true,
  children,
}) {
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const ErrorToast = errorToast;

  const submitForm = async (e, formRef) => {
    e.preventDefault();
    e.stopPropagation();
    setFormErrors({});
    setLoading(true);
    const elements = formRef?.current || e.currentTarget;
    const fd = new FormData(elements);
    let formData = {};

    new Set(fd.keys()).forEach((key) => {
      const value = fd.getAll(key);
      formData[key] = value.length > 1 ? value : value[0];
    });

    // We can add custom parsing of the data before it is validated
    if (parseData) formData = await parseData(formData);

    try {
      await validationSchema.validate(formData, { abortEarly: false });
      const { errors } = (await onSubmit(formData)) || {};

      if (Object.keys(errors || {}).length > 0) throw new ValidationError(errors);
    } catch (err) {
      const errors = // eslint-disable-next-line no-nested-ternary
        err.message.constructor === Object
          ? err.message
          : err instanceof ValidationError
            ? validationErrorToObject(err)
            : { api: "Something went wrong!" };

      if (scrollOnSubmit || ("api" in errors && Object.keys(errors).length >= 2))
        scrollToFirstError(elements, errors);

      if (
        !document.getElementsByName(Object.keys(errors)[0]).length &&
        "api" in errors &&
        ErrorToast
      ) {
        toast(
          <ErrorToast
            errorMessage={errors.api.message}
            errorProps={{
              defaultReason: "issue",
              defaultPage: errorPage,
              apiError: errors.api,
            }}
          />,
          {
            limit: 1,
          },
        );
      }

      setFormErrors(errors);
    }

    setLoading(false);
  };

  return (
    <>
      {children({
        handleSubmit: submitForm,
        errors: formErrors,
        loading,
      })}
    </>
  );
}

ValidateForm.propTypes = {
  validationSchema: PropTypes.instanceOf(ObjectSchema).isRequired,
  onSubmit: PropTypes.func.isRequired,
  errorPage: PropTypes.string,
  toast: PropTypes.elementType,
  parseData: PropTypes.func,
  scrollOnSubmit: PropTypes.bool,
  children: PropTypes.func.isRequired,
};

export default ValidateForm;
