import { useContext } from "react";
import dayjs from "dayjs";
import FacebookIcon from "icons/FacebookIcon";
import InstagramIcon from "icons/InstagramIcon";
import TwitterIcon from "icons/TwitterIcon";

import { NavigationConfigContext } from "features/navigation";

import { Container } from "ui";

export default function ProspectFooter() {
  const { config } = useContext(NavigationConfigContext);

  return (
    <footer
      className={`bg-content4 text-primary-foreground contain-paint rounded-t-3xl -mt-12 relative hidden z-40 ${config.navbar.collapse}:block`}
    >
      <Container>
        <div className="py-4 flex justify-between uppercase">
          <div className="flex items-center gap-2">
            <span>&copy;</span>

            <span>{dayjs().year()} - Oditi</span>
          </div>

          <div className="flex justify-between items-center gap-4 md:gap-6">
            <a href="https://www.instagram.com/_oditi/" target="__blank">
              <InstagramIcon className="!size-8 p-1" />
            </a>
            <a href="https://www.facebook.com/Prospect100Network" target="__blank">
              <FacebookIcon className="!size-8 p-1" />
            </a>
            <a href="https://twitter.com/prospect100" target="__blank">
              <TwitterIcon className="!size-8 p-1" />
            </a>

            <a
              href="mailto:harrybeard@oditi.com?subject=I%20want%20to%20run%20a%20brief"
              target="__blank"
            >
              <span>Contact</span>
            </a>

            <a
              href="https://docs.google.com/document/d/110GBcyDBMh8JgiUykEgyOcuacAAxUwINBaU7-78ZY9E/edit?usp=sharing"
              target="__blank"
            >
              <span>Terms</span>
            </a>

            <a href="https://www.termsfeed.com/live/6ec61b62-d177-4fb4-866b-6491a5595d9b">
              <span>Privacy</span>
            </a>
          </div>
        </div>
      </Container>
    </footer>
  );
}
