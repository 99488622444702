import { useMemo } from "react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";
import { tv } from "tailwind-variants";

import Progress from "./Progress";

const dateProgressBar = tv({
  slots: {
    base: "text-center w-full flex flex-col gap-2",
    title: "text-lg text-primary-500",
    wrapper: "bg-content1 p-2 rounded-full",
    dates: "text-xl",
  },
});

export default function DateProgressBar({
  startDate,
  endDate,
  title,
  className = "",
  classNames = {},
}) {
  const progress = useMemo(() => {
    if (Date.now() >= dayjs(endDate)) return 100;

    if (Date.now() <= dayjs(startDate)) return 0;

    const length = dayjs(endDate) - dayjs(startDate);
    const current = Date.now() - dayjs(startDate);
    return (current / length) * 100;
  }, [startDate, endDate]);

  const visible = useMemo(
    () => Date.now() > dayjs(startDate) && Date.now() < dayjs(endDate),
    [startDate],
  );

  const {
    base: baseClassNames,
    title: titleClassNames,
    wrapper: wrapperClassNames,
    dates: datesClassNames,
  } = dateProgressBar();

  return (
    <div
      className={twMerge(
        baseClassNames(),
        !visible && "hidden md:flex",
        className,
        classNames.base,
      )}
    >
      {title !== "" && (
        <span className={twMerge(titleClassNames(), classNames.title)}>{title}</span>
      )}

      <div className={twMerge(wrapperClassNames(), classNames.wrapper)}>
        <Progress value={progress} />
      </div>

      <span className={twMerge(datesClassNames(), classNames.dates)}>
        {dayjs(startDate).format("MMM D")} - {dayjs(endDate).format("MMM D")}
      </span>
    </div>
  );
}

DateProgressBar.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  classNames: PropTypes.shape({
    base: PropTypes.string,
    title: PropTypes.string,
    wrapper: PropTypes.string,
    dates: PropTypes.string,
  }),
};
