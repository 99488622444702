import { useContext } from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PropTypes from "prop-types";
import Countdown from "react-countdown";
import { twMerge } from "tailwind-merge";
import { tv } from "tailwind-variants";

import BriefContext from "features/brief/context/BriefContext";
import { briefStatusContent } from "features/brief/data/briefData";

function CountdownRenderer({ formatted, completed, classNames = {} }) {
  const denotionClassName = twMerge("ml-1", classNames.denotion);
  const days = formatted.days.split("");
  const hours = formatted.hours.split("");
  const minutes = formatted.minutes.split("");

  return (
    <div
      className={twMerge(
        "flex gap-3 text-sm uppercase font-bold flex-wrap",
        completed && "invisible",
        classNames.content,
      )}
      data-testid="countdown"
      data-slot="content"
    >
      <div className={classNames.wrapper} data-slot="wrapper">
        {days.map((part) => (
          <span className={classNames.count} data-slot="count">
            {part}
          </span>
        ))}
        <span className={denotionClassName} data-slot="denotion">
          {days === 1 ? "Day" : "Days"}
        </span>
      </div>

      <div className={classNames.wrapper} data-slot="wrapper">
        {hours.map((part) => (
          <span className={classNames.count} data-slot="count">
            {part}
          </span>
        ))}
        <span className={denotionClassName} data-slot="denotion">
          {hours === 1 ? "Hour" : "Hours"}
        </span>
      </div>

      <div className={classNames.wrapper} data-slot="wrapper">
        {minutes.map((part) => (
          <span className={classNames.count} data-slot="count">
            {part}
          </span>
        ))}
        <span className={denotionClassName} data-slot="denotion">
          Mins
        </span>
      </div>
    </div>
  );
}
CountdownRenderer.propTypes = {
  formatted: PropTypes.shape({
    days: PropTypes.string.isRequired,
    hours: PropTypes.string.isRequired,
    minutes: PropTypes.string.isRequired,
  }).isRequired,
  completed: PropTypes.bool.isRequired,
  classNames: PropTypes.shape({
    content: PropTypes.string,
    wrapper: PropTypes.string,
    count: PropTypes.string,
    denotion: PropTypes.string,
  }),
};

const briefStatus = tv({
  slots: {
    base: "flex items-center justify-center w-full gap-3",
    status: "text-lg md:text-xl",
  },
});

function BriefStatus({
  className = "",
  classNames = {},
  countdownClassNames = {},
  showHelpButton = true,
  ...countdownProps
}) {
  const { brief } = useContext(BriefContext);
  const { status, deadline } = briefStatusContent(brief)[brief.status];
  const { base: baseClassNames, status: statusClassNames } = briefStatus();

  return (
    <div className={twMerge(baseClassNames(), className, classNames.base)} data-slot="base">
      <div className={classNames.wrapper}>
        <span className={twMerge(statusClassNames(), classNames.status)} data-slot="status">
          {status}
        </span>
        {status !== "Accepting New Submissions" && (
          <div className="flex items-center mt-1">
            <Countdown
              date={deadline ? brief[deadline] : Date.now()}
              renderer={(props) => CountdownRenderer({ classNames: countdownClassNames, ...props })}
              zeroPadTime={1}
              zeroPadDays={1}
              {...countdownProps}
            />
          </div>
        )}
      </div>

      {showHelpButton && (
        <div className="flex justify-end ml-auto">
          <HelpOutlineIcon className="!size-8" />
        </div>
      )}
    </div>
  );
}
BriefStatus.propTypes = {
  className: PropTypes.string,
  classNames: PropTypes.shape({
    base: PropTypes.string,
    wrapper: PropTypes.string,
    status: PropTypes.string,
  }),
  showHelpButton: PropTypes.bool,
  countdownClassNames: PropTypes.shape({
    content: PropTypes.string,
    wrapper: PropTypes.string,
    count: PropTypes.string,
    denotion: PropTypes.string,
  }),
};

export default BriefStatus;
