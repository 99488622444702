import { useContext, useEffect, useState } from "react";
import ImageNotSupportedOutlinedIcon from "@mui/icons-material/ImageNotSupportedOutlined";
import { useParams } from "react-router-dom";

import { PageLayout } from "features/layout";
import { ProfileBanner } from "features/profile";
import { NotFound } from "features/report";
import { SubmissionCard, useSubmissions } from "features/submission";
import { UserContext } from "features/user";

import { AutoScrollLoader, CardGrid, Chip, Container, Tab, Tabs } from "ui";

import useKeyGen from "hooks/useKeyGen";

import api from "adapters/api";

export default function UserProfile() {
  const { user: currentUser } = useContext(UserContext);
  const { username } = useParams();
  const [user, setUser] = useState({});
  const [userLoading, setUserLoading] = useState(true);
  const [tab, setTab] = useState("active");

  const keyGen = useKeyGen();
  const published = useSubmissions({
    user_id: username,
    published: true,
    type: ["submission", "proposal"],
  });
  const drafts = useSubmissions({
    user_id: username,
    published: false,
    type: ["submission", "proposal"],
  });
  const submissions = tab !== "drafts" ? published : drafts;

  useEffect(() => {
    const getUser = async () => {
      setUserLoading(true);

      const res = await api.get({ url: `users/${username}` });
      if (res.success) setUser(res.data);

      setUserLoading(false);
    };
    getUser();
    // When we change user, we want the default tab to be active
    setTab("active");
  }, [username]);

  return (
    <PageLayout
      pageName="Profile"
      showFooter={!submissions.hasNextPage}
      meta={{
        currentRoute: "userProfile",
      }}
    >
      {Object.keys(user).length > 0 || userLoading || submissions.isLoading ? (
        <>
          <div className="bg-content2 rounded-t-3xl">
            {!userLoading ? (
              <ProfileBanner.User
                profile={user}
                entries={drafts.total + published.total}
                edit={currentUser.id === user.id ? "/user/edit" : ""}
                key={keyGen.getKey(user)}
              />
            ) : (
              <ProfileBanner.Loading />
            )}
          </div>

          <div className="bg-background -mt-12 rounded-t-3xl">
            <Container className="after:pb-12 after:block py-6">
              <div className="flex flex-wrap gap-4 items-center justify-end">
                {currentUser.id === user.id && !userLoading && (
                  <Tabs
                    color="primary"
                    radius="full"
                    size="lg"
                    disabledKeys={[...(drafts.total <= 0 ? ["drafts"] : [])]}
                    onSelectionChange={setTab}
                    classNames={{
                      base: "w-full sm:w-auto",
                      tabList: "w-full sm:w-auto",
                    }}
                  >
                    <Tab
                      key="active"
                      title={
                        <div className="flex items-center space-x-2">
                          <span>Submissions</span>
                          <Chip size="sm" variant="faded">
                            {published.total}
                          </Chip>
                        </div>
                      }
                    />
                    <Tab
                      key="drafts"
                      title={
                        <div className="flex items-center space-x-2">
                          <span>Drafts</span>
                          <Chip size="sm" variant="faded">
                            {drafts.total}
                          </Chip>
                        </div>
                      }
                    />
                  </Tabs>
                )}
              </div>

              <CardGrid entries={submissions.all} card={SubmissionCard} sizing={{ md: 6, xl: 4 }} />

              <AutoScrollLoader
                fetchNextPage={submissions.fetchNextPage}
                hasNextPage={submissions.hasNextPage}
                isLoading={submissions.isLoading || submissions.isFetchingNextPage}
                error={submissions.error}
                empty={!submissions.all.length}
              >
                <ImageNotSupportedOutlinedIcon className="!size-12 mb-4 text-default-300 !block mx-auto" />
                <span className="font-roman text-default-300">No Submissions Yet</span>
              </AutoScrollLoader>
            </Container>
          </div>
        </>
      ) : (
        <NotFound
          text={"Bummer. \n The requested user could not be found or does not exist."}
          issuePage="profile"
        />
      )}
    </PageLayout>
  );
}
