import { forwardRef } from "react";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import VoteIcon from "icons/VoteIcon";
import PropTypes from "prop-types";

import { useProtectedAction } from "features/authentication";
import useSubmissionWithActions from "features/submission/hooks/useSubmissionWithActions";

import animationStyles from "ui/animations";

import useAnimateVoteButton from "hooks/useAnimateVoteButton";
import { abbreviateNumber } from "utils/helpers";

import Mixpanel from "adapters/mixpanel";

import StickyActionButton from "./StickyActionButton";

const VotedIcon = forwardRef(({ votes, animateVerified, animateVotes, ...props }, ref) => (
  <div ref={ref} {...props}>
    <div className="flex flex-col items-center justify-center h-full">
      <VerifiedOutlinedIcon ref={animateVerified} className="!size-4" />
      <small className="text-xs" ref={animateVotes}>
        {abbreviateNumber(votes, 1)}
      </small>
    </div>
  </div>
));

VotedIcon.propTypes = {
  votes: PropTypes.number.isRequired,
  animateVerified: PropTypes.func.isRequired,
  animateVotes: PropTypes.func.isRequired,
};

export default function SubmissionStickyActionVoteButton({
  submissionId,
  hasVoted = true,
  numberVotes = 0,
  allowSelectWinner = false,
  ...props
}) {
  const { animateVerified, animateVotes } = useAnimateVoteButton(
    hasVoted,
    animationStyles.bounceInUp,
  );
  const { useSubmissionVote } = useSubmissionWithActions(submissionId);

  const [handleClickVoteButton] = useProtectedAction(async () => {
    await useSubmissionVote.mutateAsync();
    Mixpanel.trackButton("Action Button", "vote", "submission actions");
  });

  if (hasVoted || allowSelectWinner) {
    return (
      <StickyActionButton
        title={allowSelectWinner ? "Votes" : "Voted"}
        tooltip={allowSelectWinner ? "Votes Received" : "Thanks For Your Vote"}
        icon={VotedIcon}
        iconProps={{
          animateVerified,
          animateVotes,
          votes: numberVotes,
        }}
        {...props}
      />
    );
  }

  return (
    <StickyActionButton
      title="Vote"
      tooltip="Vote for this submission"
      icon={VoteIcon}
      onClick={() => handleClickVoteButton()}
      isLoading={useSubmissionVote.isPending}
      {...props}
    />
  );
}

SubmissionStickyActionVoteButton.propTypes = {
  submissionId: PropTypes.number.isRequired,
  hasVoted: PropTypes.bool,
  numberVotes: PropTypes.number,
  allowSelectWinner: PropTypes.bool,
};
