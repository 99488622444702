import { css } from "aphrodite";
import backgroundImage from "images/instagram_grid.jpg";
import highlight2 from "images/uploads/round21_origin_paddle-3.jpg";
import highlight1 from "images/uploads/zellerfeld-washable-3d-printed-shoes-designboom-10.webp";
import PropTypes from "prop-types";
import { useInView } from "react-intersection-observer";

import { PageLayout } from "features/layout";
import { SubmissionVoteBlock } from "features/submission";

import { Col, Container, Row, ScrollSections, TitleBanner } from "ui";
import animationStyles from "ui/animations";

function ScrollInViewSection({ children }) {
  const { ref, inView } = useInView();

  return (
    <div
      ref={ref}
      className={`${inView ? `${css(animationStyles.fadeInUp)} visible` : "invisible"} text-xl`}
    >
      {children}
    </div>
  );
}

ScrollInViewSection.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function About() {
  return (
    <PageLayout
      pageName="About"
      className="text-white"
      navbarProps={{
        size: "none",
      }}
    >
      <TitleBanner
        titleComponents={["We are", "Oditi"]}
        backgroundImage={backgroundImage}
        subText={
          <>
            We believe the best ideas can come from anyone, anywhere.
            <br />
            Co-create alongside the world&#39;s most iconic brands.
            <br />
            <b>#YourFutureStartsNow</b>
          </>
        }
      />

      <ScrollSections>
        <ScrollSections.ScrollSection className="bg-content4 rounded-t-3xl -mt-12" first>
          <Row className="md:flex-row-reverse" gap={6}>
            <Col xl={6} className="py-8">
              <div className="w-full h-full rounded-3xl overflow-hidden flex items-center">
                <div className="aspect-4/3 w-full rounded-3xl overflow-hidden">
                  <img
                    src={highlight2}
                    className="mb-0 w-full object-cover"
                    alt="Oditi highlight 1"
                  />
                </div>
              </div>
            </Col>

            <Col xl={6} className="flex flex-col justify-center">
              <h2 className="mb-4 lg:mb-8">The Mission</h2>

              <ScrollInViewSection>
                <p className="text-xl">
                  Founded during lockdown by Adam (18), Alexandre (22), and Harry (20), Oditi
                  started as an Instagram account hosting briefs to help keep creatives inspired and
                  provide opportunities.
                </p>
                <p className="text-xl">
                  We believe that the best ideas can come from anyone, anywhere, and Oditi helps
                  bring those ideas to light - regardless of an applicant&#39;s age, accolades or
                  audience.
                </p>
                <p className="text-xl">
                  Oditi enables you to co-create with organizations. All voices have an equal chance
                  to be heard, supported, and embraced by forward thinking brands.
                </p>
              </ScrollInViewSection>
            </Col>
          </Row>
        </ScrollSections.ScrollSection>

        <ScrollSections.ScrollSection className="bg-content2 text-foreground rounded-t-3xl" last>
          <Row gap={6}>
            <Col xl={6} className="py-8">
              <div className="w-full h-full rounded-3xl overflow-hidden flex items-center">
                <div className="aspect-4/3 w-full rounded-3xl overflow-hidden">
                  <img
                    src={highlight1}
                    className="mb-0 w-full object-cover"
                    alt="Oditi highlight 2"
                  />
                </div>
              </div>
            </Col>

            <Col xl={6} className="flex flex-col justify-center">
              <h2 className="mb-4 lg:mb-8">Our Commitments to You</h2>

              <ScrollInViewSection>
                <ol>
                  <li>
                    <p>
                      Create a digital space where your voice is heard on a level playing field,
                      without fear of judgement.
                    </p>
                  </li>
                  <li>
                    <p>
                      Bring creative opportunities, enabling you to showcase your talents and excel
                      in the design world.
                    </p>
                  </li>
                  <li>
                    <p>
                      To continuously listen, learn, and evolve to best serve your needs and help
                      you reach the heights you deserve.
                    </p>
                  </li>
                </ol>

                <p>
                  Join us in shaping the future of design by unlocking the untapped potential of
                  innovative thinkers worldwide. Together, we can redefine the creative landscape
                  and drive a new era of design collaboration.
                </p>
              </ScrollInViewSection>
            </Col>
          </Row>
        </ScrollSections.ScrollSection>
      </ScrollSections>

      <div className="bg-background text-foreground rounded-t-3xl -mt-12 relative">
        <Container className="after:pb-12 after:block py-12">
          <SubmissionVoteBlock color="primary" />
        </Container>
      </div>
    </PageLayout>
  );
}
