import { useContext, useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { AuthContext } from "features/authentication";
import UserContext from "features/user/context/UserContext";

import { Progress } from "ui";

import OnboardingAvatar from "./OnboardingAvatar";
import OnboardingComplete from "./OnboardingComplete";
import OnboardingTopics from "./OnboardingTopics";
import OnboardingUser from "./OnboardingUser";
import OnboardingUsername from "./OnboardingUsername";

const TOTALSTEPS = 5;

export default function Onboarding() {
  const { user } = useContext(UserContext);
  const { hideAuth } = useContext(AuthContext);
  const [step, setStep] = useState(1);

  useEffect(() => {
    // If the user has already completed the onboarding then we don't want them to see this page.
    if (user.metadata?.completed_onboarding) hideAuth();
  }, [user]);

  const handleCompleteOnboarding = () => {
    // If on mobile, scroll back to the top of the page
    window.scrollTo({ top: 0, left: 0 });
    hideAuth();
  };

  return (
    <div className="p-8 rounded-3xl" data-testid="user-onboarding">
      <div className="flex flex-col gap-4 mb-4">
        <div className="flex items-center">
          {step > 1 && (
            <ArrowBackIcon
              className="!size-5 mr-2"
              role="button"
              onClick={() => setStep((prev) => prev - 1)}
            />
          )}
          <div className="bg-content1 p-1 rounded-full border-2 border-primary w-full">
            <Progress value={(100 / TOTALSTEPS) * step} />
          </div>
        </div>
        <b className="block text-xl">
          Step {step}/{TOTALSTEPS}
        </b>
      </div>

      {step === 1 && <OnboardingUsername onComplete={() => setStep(2)} />}

      {step === 2 && <OnboardingAvatar onComplete={() => setStep(3)} />}

      {step === 3 && <OnboardingUser onComplete={() => setStep(4)} />}

      {step === 4 && <OnboardingTopics onComplete={() => setStep(5)} />}

      {step === 5 && <OnboardingComplete onComplete={handleCompleteOnboarding} />}
    </div>
  );
}
