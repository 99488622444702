import { useContext } from "react";

import { UserContext } from "features/user";

import { client, useMany, useMutation, useOne, useQueryClient } from "api";

const baseURL = "briefs/";

const briefQueries = {
  all: () => [{ url: baseURL }],
  lists: () => [{ url: baseURL, view: "list" }],
  list: (params) => [{ url: baseURL, view: "list", params }],
  details: () => [{ url: baseURL, view: "detail" }],
  detail: (id) => [{ url: baseURL, view: "detail", id: id == null ? id : String(id) }],
};

const useBriefs = (params) => useMany({ queryKey: briefQueries.list(params) });
const useBrief = (id) => useOne({ queryKey: briefQueries.detail(id) });

const useEnterBriefMutations = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(UserContext);

  return {
    enterBrief: useMutation({
      mutationFn: ({ id }) => client.put(`briefs/${id}/enter/`),
      onSuccess: ({ data: brief }) => {
        queryClient.setQueryData(briefQueries.detail(brief.id), brief);
        queryClient.invalidateQueries({
          queryKey: briefQueries.detail(brief.id),
        });
      },
    }),
    leaveBrief: useMutation({
      mutationFn: ({ id, reason }) => client.put(`briefs/${id}/enter/`, { not_interested: reason }),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: briefQueries.lists({ username: user.username }),
        });
      },
    }),
  };
};

export { useBrief, useBriefs, useEnterBriefMutations, briefQueries };
