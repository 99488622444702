import { useMemo, useState } from "react";

export default function useNavigationConfigProvider(config) {
  const [configContext, setConfigContext] = useState(config);

  const configContextProviderValue = useMemo(
    () => ({
      config: configContext,
      setConfig: (conf) => {
        setConfigContext(conf);
      },
    }),
    [configContext],
  );

  return { configContextProviderValue };
}
