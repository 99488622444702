import { useContext } from "react";

import { BriefContext, BriefStatus } from "features/brief";
import { User } from "features/user";

export default function LandingCountdown() {
  const { brief } = useContext(BriefContext);

  return (
    <div className="flex flex-col gap-6 items-center px-4">
      <User
        name={brief.organisation.name}
        avatarProps={{
          src: brief.organisation.icon,
        }}
        className="bg-transparent"
      />

      <h2 className="max-w-[800px] text-center">{brief.title}</h2>

      {brief.challenge_statement && <p className="text-center">{brief.challenge_statement}</p>}

      <BriefStatus
        showHelpButton={false}
        classNames={{
          status: "text-center block",
        }}
        countdownClassNames={{
          content: "flex-nowrap gap-0 px-2 max-w-96",
          wrapper: "flex flex-wrap gap-2 justify-center",
          count: "block bg-primary p-2 px-4 rounded flex items-center justify-center aspect-square",
          denotion: "m-0 text-center w-full",
        }}
        zeroPadTime={2}
        zeroPadDays={2}
      />
    </div>
  );
}
