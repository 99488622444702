import { motion } from "framer-motion";
import iso3311a2 from "iso-3166-1-alpha-2";
import PropTypes from "prop-types";

import {
  formatPosition,
  formatScore,
  LeaderboardList,
  useCountryLeaderboard,
  useLeaderboard,
} from "features/leaderboard";

import { Accordion, AutoScrollLoader, Spinner } from "ui";

function UserList({ leaderboardName, country }) {
  const leaderboard = useLeaderboard(leaderboardName, { country_code: country, page_size: 10 });

  return (
    <LeaderboardList
      isLoading={leaderboard.isLoading}
      entries={leaderboard.all}
      scoreName={leaderboardName}
      size="lg"
    />
  );
}
UserList.propTypes = {
  leaderboardName: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
};

export default function CountryLeaderboardList({ leaderboardName }) {
  const leaderboard = useCountryLeaderboard(leaderboardName);

  if (leaderboard.isLoading) {
    return (
      <div className="w-full flex justify-center mt-6">
        <Spinner size="lg" />
      </div>
    );
  }

  return (
    <>
      <Accordion variant="splitted" className="p-1">
        {leaderboard.all.map(({ country_code: countryCode, score }, index) => (
          <Accordion.AccordionItem
            as={motion.div}
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            key={countryCode}
            aria-label="Accordion 1"
            title={
              <div className="flex flex-col">
                <div className="ordinal text-sm font-light text-default-500 me-2">
                  {formatPosition(index + 1)}
                </div>
                <div className="">{iso3311a2.getCountry(countryCode) ?? countryCode}</div>
              </div>
            }
            subtitle={`${formatScore(score)} ${leaderboardName === "briefs" ? "brief completions" : leaderboardName}`}
            startContent={
              <img
                src={`https://flagcdn.com/56x42/${countryCode.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/112x84/${countryCode.toLowerCase()}.png 2x,
            https://flagcdn.com/168x126/${countryCode.toLowerCase()}.png 3x`}
                alt={countryCode}
                className="w-11 sm:w-auto"
              />
            }
            classNames={{
              base: "shadow-none bg-default-100 px-2",
              trigger: "py-4",
              heading: "m-0",
              title: "flex items-center gap-6 uppercase",
              subtitle: "uppercase font-roman text-xs",
              startContent: "flex items-center text-5xl mx-3",
              content: "pt-0",
            }}
          >
            <div className="ps-4">
              <div className="ps-6 py-2 text-sm font-light">
                {iso3311a2.getCountry(countryCode) ?? countryCode}&apos;s Top 10 Odities
              </div>
              <UserList leaderboardName={leaderboardName} country={countryCode} />
            </div>
          </Accordion.AccordionItem>
        ))}
      </Accordion>

      <AutoScrollLoader
        fetchNextPage={leaderboard.fetchNextPage}
        hasNextPage={leaderboard.hasNextPage}
        isLoading={leaderboard.isLoading || leaderboard.isFetchingNextPage}
        error={leaderboard.error}
        empty={!leaderboard.all.length}
      />
    </>
  );
}
CountryLeaderboardList.propTypes = {
  leaderboardName: PropTypes.string.isRequired,
};
