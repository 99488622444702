import PropTypes from "prop-types";

import UserBadge from "./UserBadge";

export default function UserBadgeList({ badges = [], className = "" }) {
  if (!badges || badges.length === 0) return null;

  return (
    <div className="flex">
      {badges.map((badgeKey) => (
        <UserBadge key={badgeKey} badgeKey={badgeKey} className={className} />
      ))}
    </div>
  );
}
UserBadgeList.propTypes = {
  badges: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
};
