import { useContext } from "react";
import SendIcon from "@mui/icons-material/Send";
import PropTypes from "prop-types";

import { useFeedbackMutations } from "features/feedback/api";
import feedbackPropTypes from "features/feedback/feedbackSchema";
import { ErrorToast } from "features/report";
import { UserContext, userPropTypes } from "features/user";

import { Form, handleFormError, Textarea, useForm } from "forms";
import { Avatar, Modal } from "ui";

export default function FeedbackPromptModal({
  submissionId,
  designer,
  onHide,
  show = false,
  request = undefined,
}) {
  const {
    createFeedback: { mutate, isPending },
  } = useFeedbackMutations(submissionId);
  const { user } = useContext(UserContext);
  const {
    register,
    watch,
    reset,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const handleClose = () => {
    reset();
    onHide();
  };

  return (
    <Modal isOpen={show} onClose={onHide} data-testid="feedback-prompt-modal">
      <Modal.ModalBody className="p-6">
        <div className="flex justify-center">
          <Avatar
            src={designer.icon}
            name={designer.username}
            size="lg"
            country={designer.country_code}
            className="!size-20"
          />
        </div>

        <section className="flex flex-col my-4 text-center justify-center">
          <span className="text-3xl">
            Hi <span className="capitalize">{user.given_name || ""}</span>, thanks for your vote!
          </span>
          <span className="uppercase text-primary-600">One more step</span>
        </section>

        <Form
          onSubmit={handleSubmit(({ feedback }) =>
            mutate(
              { content: feedback, ...(request?.id && { parent: request?.id }) },
              {
                onSuccess: handleClose,
                onError: (error, data) =>
                  handleFormError({
                    error,
                    data,
                    setError,
                    errorPage: "voting",
                    toast: ErrorToast,
                  }),
              },
            ),
          )}
        >
          <Textarea
            {...register("feedback", {
              maxLength: {
                value: 600,
                message: "Feedback cannot be longer than 600 characters",
              },
            })}
            maxLength={600}
            minRows={5}
            maxRows={10}
            placeholder={`What did you love most about ${designer.username.toUpperCase()}'s design? Help them out by leaving some feedback (min 10 characters).`}
            isInvalid={!!errors.feedback}
            errorMessage={errors.feedback?.message}
          />

          <Modal.ModalActionsFooter
            onCancel={handleClose}
            cancelText="Skip"
            confirmText="Send Feedback"
            cancelProps={{
              variant: "light",
            }}
            confirmProps={{
              endContent: <SendIcon />,
              isDisabled: !!errors.feedback || !watch("feedback") || watch("feedback")?.length < 10,
              isLoading: isPending,
            }}
          />
        </Form>
      </Modal.ModalBody>
    </Modal>
  );
}
FeedbackPromptModal.propTypes = {
  submissionId: PropTypes.number.isRequired,
  designer: userPropTypes.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool,
  request: feedbackPropTypes,
};
